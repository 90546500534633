import { useNavigate } from 'react-router-dom';
import { CallbackComponent } from 'redux-oidc';

import userManager from './userManager';

/**
 * This component is responsible for providing callsbacks for the OIDC-client, so that WHEN the user
 * is succesfully authenticated, they will either be redirected to the page they came from or they'll see
 * an error page.
 * @returns Component
 */
const CallbackPage = () => {
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={() => {
        const redirectUri = localStorage.getItem('redirect_uri');
        navigate(redirectUri ? decodeURIComponent(redirectUri) : '/error/missingRedirectUri', { replace: true });
      }}
      errorCallback={() => {
        navigate('/error/error_logging_in', { replace: true });
      }}
    >
      {params.error && params.error_description ? (
        <div>
          <p>Er is een fout opgegetreden:</p>
          <p>{params.error_description}</p>
        </div>
      ) : (
        <div>Redirecting...</div>
      )}
    </CallbackComponent>
  );
};

export default CallbackPage;

import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { stringDateToLocaleString } from 'utils/dateUtils';

import { useGetDepartmentById } from '../services/api';

export const DepartmentInformation: React.FC<{ departmentId: number }> = ({ departmentId }) => {
  const { data, isLoading } = useGetDepartmentById({ id: departmentId });

  const { t } = useTranslation();
  if (isLoading || !data) {
    return (
      <div style={{ fontSize: 25 }}>
        <Skeleton count={3} />
      </div>
    );
  }
  let creationDate = 'Onbekend';
  let lastModifiedDate = 'Onbekend';
  if (data.creationDate) {
    creationDate = stringDateToLocaleString(data.creationDate);
  }
  if (data.lastModifiedDate) {
    lastModifiedDate = stringDateToLocaleString(data.lastModifiedDate);
  }
  return (
    <div className='mt-2 divide-y divide-gray-200'>
      <dl className='divide-y divide-gray-200'>
        <div className='py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4'>
          <dt className='text-sm font-medium text-gray-500'>{t('Naam afdeling')}</dt>
          <dd className='flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3'>
            <span className='flex-grow'>{data.name}</span>
          </dd>
        </div>
        <div className='py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4'>
          <dt className='text-sm font-medium text-gray-500'>{t('Aangemaakt door')}</dt>
          <dd className='flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3'>
            <span className='flex-grow'>{data.createdBy}</span>
          </dd>
        </div>
        <div className='py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4'>
          <dt className='text-sm font-medium text-gray-500'>{t('Datum aangemaakt')}</dt>
          <dd className='flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3'>
            <span className='flex-grow'>{creationDate}</span>
          </dd>
        </div>
        <div className='py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4'>
          <dt className='text-sm font-medium text-gray-500'>{t('Laatst gewijzigd door')}</dt>
          <dd className='flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3'>
            <span className='flex-grow'>{data.lastModifiedBy}</span>
          </dd>
        </div>
        <div className='py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:border-b sm:border-gray-200'>
          <dt className='text-sm font-medium text-gray-500'>{t('Datum wijziging')}</dt>
          <dd className='flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3'>
            <span className='flex-grow'>{lastModifiedDate}</span>
          </dd>
        </div>
      </dl>
    </div>
  );
};

import { useTranslation } from 'react-i18next';

interface PanelProps {
  onSliderClosed: () => void;
  isLoading: boolean;
}

export const PanelSubmit: React.FC<PanelProps> = ({ onSliderClosed, isLoading }: PanelProps) => {
  const { t } = useTranslation();
  return (
    <div className='flex justify-end flex-shrink-0 px-4 py-4'>
      <button
        type='button'
        disabled={isLoading}
        className='px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
        onClick={onSliderClosed}
      >
        {t('Annuleren')}
      </button>
      {!isLoading && (
        <button
          disabled={isLoading}
          type='submit'
          className='inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white bg-yellow-600 border border-transparent rounded-md shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
        >
          {t('Opslaan')}
        </button>
      )}
      {isLoading && (
        <span className='inline-flex rounded-md shadow-sm'>
          <button
            type='button'
            className='inline-flex items-center px-4 py-2 text-sm font-medium leading-6 text-white transition duration-150 ease-in-out bg-yellow-700 border border-transparent rounded-md cursor-not-allowed '
            disabled={true}
          >
            <svg
              className='w-5 h-5 mr-3 -ml-1 text-white animate-spin'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
            >
              <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
              <path
                className='opacity-75'
                fill='currentColor'
                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
              ></path>
            </svg>
            {t('Bezig met opslaan')}
          </button>
        </span>
      )}
    </div>
  );
};

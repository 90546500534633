import { Card } from 'features/generic/Card';

import logo from '../../assets/images/logo/tra_logo_wit.png';

export const LoggedOutPage = () => {
  return (
    <div className='flex flex-col items-center justify-start h-screen pt-4 overflow-hidden bg-tra-grey'>
      <Card className='pb-4 w-96'>
        <div className='p-4 bg-black'>
          <img src={logo} className='h-16'></img>
        </div>
        <h3 className='px-4 mt-6 text-3xl font-bold text-black'>U bent uitgelogd</h3>
        <p className='px-4 mt-6 text-base font-semibold text-gray-800'>U kunt nu deze pagina afsluiten</p>
      </Card>
    </div>
  );
};

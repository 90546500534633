/* eslint-disable @typescript-eslint/no-empty-function */
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import i18n from '../../i18n/config';
import { AdminEmployeeInfoDto } from '../services/apiGenerated';
import { PanelBase } from './components/PanelBase';
import { PanelContents } from './components/PanelContents';
import { PanelErrorFooter } from './components/PanelError';
import { PanelSubmit } from './components/PanelSubmit';

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .max(50, i18n.t('Naam mag minimaal 50 karakters bevatten'))
    .required(i18n.t('Dit veld is verplicht')),
  lastName: yup
    .string()
    .max(50, i18n.t('Naam mag minimaal 50 karakters bevatten'))
    .required(i18n.t('Dit veld is verplicht')),
  email: yup.string().email().required(i18n.t('Dit veld is verplicht')),
});

interface PanelProps {
  isOpened: boolean;
  onClose: () => void;
  onSubmit: (dto: Partial<AdminEmployeeInfoDto>) => void;
  title: string;
  description: string;
  adminUser?: AdminEmployeeInfoDto;
  isLoading: boolean;
  error?: SerializedError | FetchBaseQueryError | undefined;
}

export const AdminUserUpsertPanel: React.FC<PanelProps> = ({
  isOpened,
  onClose,
  onSubmit,
  title,
  description,
  adminUser,
  isLoading,
  error,
}: PanelProps) => {
  const onSliderClosed = () => {
    onClose();
  };
  const { t } = useTranslation();
  return (
    <PanelBase isOpened={isOpened}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          firstName: adminUser?.firstName ?? '',
          lastName: adminUser?.lastName ?? '',
          email: adminUser?.email ?? '',
        }}
        onSubmit={(values) => {
          onSubmit({
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
          });
        }}
      >
        {({
          errors,
          touched,
          // values, setValues
        }) => (
          <Form className='flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl'>
            <PanelContents title={title} description={description} onSliderClosed={onSliderClosed}>
              <div>
                <label htmlFor='project_name' className='block text-sm font-medium text-gray-900'>
                  {t('Voornaam')}
                </label>
                <div className='mt-1'>
                  <Field
                    autoComplete='false'
                    spellCheck={false}
                    type='text'
                    name='firstName'
                    id='firstName'
                    className={`block w-full border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-yellow-500 focus:border-yellow-500 ${
                      errors.firstName && touched.firstName ? 'border-red-500' : ''
                    }`}
                  />
                  {errors.firstName && touched.firstName && (
                    <span className='text-xs italic text-yellow-800'>{errors.firstName}</span>
                  )}
                </div>
              </div>
              <div>
                <label htmlFor='project_name' className='block text-sm font-medium text-gray-900'>
                  {t('Achternaam')}
                </label>
                <div className='mt-1'>
                  <Field
                    autoComplete='false'
                    spellCheck={false}
                    type='text'
                    name='lastName'
                    id='lastName'
                    className={`block w-full border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-yellow-500 focus:border-yellow-500 ${
                      errors.lastName && touched.lastName ? 'border-red-500' : ''
                    }`}
                  />
                  {errors.lastName && touched.lastName && (
                    <span className='text-xs italic text-yellow-800'>{errors.lastName}</span>
                  )}
                </div>
              </div>
              <div>
                <label htmlFor='project_name' className='block text-sm font-medium text-gray-900'>
                  {t('Email')}
                </label>
                <div className='mt-1'>
                  <Field
                    autoComplete='false'
                    spellCheck={false}
                    type='text'
                    name='email'
                    id='email'
                    className={`block w-full border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-yellow-500 focus:border-yellow-500 ${
                      errors.email && touched.email ? 'border-red-500' : ''
                    }`}
                  />
                  {errors.email && touched.email && (
                    <span className='text-xs italic text-yellow-800'>{errors.email}</span>
                  )}
                </div>
              </div>
            </PanelContents>
            <PanelErrorFooter error={error} />
            <PanelSubmit isLoading={isLoading} onSliderClosed={onSliderClosed} />
          </Form>
        )}
      </Formik>
    </PanelBase>
  );
};

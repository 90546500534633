/* This example requires Tailwind CSS v2.0+ */
import { Menu, Transition } from '@headlessui/react';
import { CloudUploadIcon } from '@heroicons/react/outline';
import {
  ChevronDownIcon,
  ExclamationIcon,
  MailIcon,
  TagIcon,
  BadgeCheckIcon,
  XCircleIcon,
} from '@heroicons/react/solid';
import { Modal } from 'features/generic/Modal';
import { UploadTaskCustomerPanel } from 'features/panels/UploadTaskCustomerPanel';
import { UploadTask } from 'features/services/apiGenerated';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { twClassNames } from '../../utils/twClassNames';
import { UploadTaskDeclinePanel } from '../panels/UploadTaskDeclinePanel';
import { UploadTaskUpsertPanel } from '../panels/UploadTaskUpsertPanel';
import {
  useDistributeUploadTask,
  useGetUploadTaskById,
  useUpdateUploadTask,
  useUpdateUploadTaskStatus,
} from '../services/api';

export const UploadTaskActionDropdown: React.FC<{ onDelete: () => void; uploadTaskId: number }> = ({
  onDelete,
  uploadTaskId,
}) => {
  const [editPanelOpened, setEditPanelOpened] = useState(false);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const [approveModalOpened, setApproveModalOpened] = useState(false);
  const [declinePanelOpened, setDeclinePanelOpened] = useState(false);
  const [distributeModalOpened, setDistributeModalOpened] = useState(false);
  const [uploadPanelOpened, setUploadPanelOpened] = useState(false);
  const [
    updateUploadTask, // This is the mutation trigger
    { isLoading: isUpdating, error }, // This is the destructured mutation result
  ] = useUpdateUploadTask();

  const [
    updateUploadTaskStatus, // This is the mutation trigger
    { isLoading: isUpdatingStatus, error: statusUpdateError }, // This is the destructured mutation result
  ] = useUpdateUploadTaskStatus();

  const { t } = useTranslation();
  const { data, refetch } = useGetUploadTaskById({ id: uploadTaskId });
  const [distributeUploadTask] = useDistributeUploadTask();
  const { data: uploadTask, refetch: refetchUploadTask } = useGetUploadTaskById({ id: uploadTaskId });
  const handleDeleteUploadTask = () => {
    setDeleteModalOpened(false);
    onDelete();
  };

  const handleDistribution = () => {
    if (data?.isActive) {
      distributeUploadTask({ taskId: uploadTaskId });
    }
  };
  const handleUpdate = (dto: Partial<UploadTask>) => {
    updateUploadTask({
      id: uploadTaskId,
      uploadTask: {
        id: uploadTaskId,
        ...dto,
      },
    })
      .unwrap()
      .then(() => {
        setEditPanelOpened(false);
      });
  };

  const handleCloseUpload = () => {
    setUploadPanelOpened(false);
    refetch();
  };
  const handleApproveUploadTask = () => {
    updateUploadTaskStatus({
      id: uploadTaskId,
      uploadTaskStatusDto: {
        taskId: uploadTaskId,
        newStatus: 'ProcessingSuccesful',
      },
    })
      .unwrap()
      .then(() => {
        setApproveModalOpened(false);
      });
  };

  const handleTaskDecline = (reason: string) => {
    updateUploadTaskStatus({
      id: uploadTaskId,
      uploadTaskStatusDto: {
        taskId: uploadTaskId,
        newStatus: 'IsErrorAndReupload',
        optionalReason: reason,
      },
    })
      .unwrap()
      .then(() => {
        setDeclinePanelOpened(false);
      });
  };
  return (
    <>
      <Menu as='div' className='relative inline-block text-left' onBlur={refetchUploadTask}>
        {({ open }) => (
          <>
            <div>
              <Menu.Button className='inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-yellow-600 border border-yellow-300 rounded-md shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-yellow-500'>
                Acties
                <ChevronDownIcon className='w-5 h-5 ml-2 -mr-1' aria-hidden='true' />
              </Menu.Button>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items
                static
                className='absolute right-0 z-50 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
              >
                <div className='py-1'>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => setDistributeModalOpened(true)}
                        className={twClassNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'group flex items-center px-4 py-2 text-sm w-full'
                        )}
                      >
                        <MailIcon className='w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500' aria-hidden='true' />
                        {t('Uploadtaak e-mailen')}
                      </button>
                    )}
                  </Menu.Item>
                </div>
                <div className='py-1'>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => setEditPanelOpened(true)}
                        className={twClassNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'group flex items-center px-4 py-2 text-sm w-full'
                        )}
                      >
                        <TagIcon className='w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500' aria-hidden='true' />
                        {t('Uploadtaak wijzigen')}
                      </button>
                    )}
                  </Menu.Item>
                </div>
                <div className='py-1'>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => setUploadPanelOpened(true)}
                        className={twClassNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'group flex items-center px-4 py-2 text-sm w-full'
                        )}
                      >
                        <CloudUploadIcon
                          className='w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500'
                          aria-hidden='true'
                        />
                        {t('Bestanden uploaden')}
                      </button>
                    )}
                  </Menu.Item>
                </div>
                {uploadTask && uploadTask.status === 'IsBeingProcessed' && (
                  <div className='py-1'>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => setApproveModalOpened(true)}
                          className={twClassNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'group flex items-center px-4 py-2 text-sm w-full'
                          )}
                        >
                          <BadgeCheckIcon
                            className='w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500'
                            aria-hidden='true'
                          />
                          {t('Data goedkeuren')}
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                )}
                {uploadTask && uploadTask.status === 'IsBeingProcessed' && (
                  <div className='py-1'>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => setDeclinePanelOpened(true)}
                          className={twClassNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'group flex items-center px-4 py-2 text-sm w-full'
                          )}
                        >
                          <XCircleIcon
                            className='w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500'
                            aria-hidden='true'
                          />
                          {t('Data afkeuren')}
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                )}
                <div className='py-1'>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => setDeleteModalOpened(true)}
                        className={twClassNames(
                          active ? 'bg-gray-100 text-red-900' : 'text-red-700',
                          'group flex items-center px-4 py-2 text-sm w-full'
                        )}
                      >
                        <ExclamationIcon
                          className='w-5 h-5 mr-3 text-red-500 group-hover:text-red-600'
                          aria-hidden='true'
                        />
                        {t('Uploadtaak verwijderen')}
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      <Modal
        isOpened={deleteModalOpened}
        onClose={() => setDeleteModalOpened(false)}
        type='error'
        title={t('Uploadtaak verwijderen')}
        description={t('Weet je zeker dat je deze uploadtaak wilt verwijderen?')}
        cancelButtonText={t('Annuleren')}
        actionButtonText={t('Verwijderen')}
        onAction={handleDeleteUploadTask}
      />
      <Modal
        isOpened={approveModalOpened}
        onClose={() => setApproveModalOpened(false)}
        type='succes'
        title={t('Uploadtaak afronden')}
        description={t(
          'Weet je zeker dat je deze uploadtaak wilt afronden? De klant ontvangt een e-mail dat de data-upload is afgerond en kan vervolgens geen bestanden meer uploaden.'
        )}
        cancelButtonText={t('Annuleren')}
        actionButtonText={t('Afronden')}
        onAction={handleApproveUploadTask}
      />
      <Modal
        isOpened={distributeModalOpened}
        onClose={() => setDistributeModalOpened(false)}
        type={data?.isActive ? 'warning' : 'error'}
        title={t('Rapportage mailen')}
        description={
          data?.isActive
            ? t('Weet je zeker dat je  dit uploadverzoek wilt mailen naar alle uploaders ({{aantal}} totaal) ?', {
                aantal: data?.dataUploaders,
              })
            : t('Het is niet mogelijk om een inactieve uploadtaak te e-mailen!')
        }
        actionButtonText={data?.isActive ? t('Ik weet het zeker') : 'Annuleren'}
        cancelButtonText={data?.isActive ? t('Annuleren') : undefined}
        onAction={() => handleDistribution()}
      />
      <UploadTaskUpsertPanel
        isOpened={editPanelOpened}
        title={t('Wijzig uploadtaak')}
        description={t('Wijzig een bestaande uploadtaak voor klanten.')}
        onClose={() => setEditPanelOpened(false)}
        onSubmit={(dto) => handleUpdate(dto)}
        uploadTask={data}
        isLoading={isUpdating}
        error={error}
      />
      <UploadTaskCustomerPanel isOpened={uploadPanelOpened} onClose={() => handleCloseUpload()} uploadTask={data} />
      <UploadTaskDeclinePanel
        isOpened={declinePanelOpened}
        onClose={() => handleCloseUpload()}
        title={t('Data-upload afkeuren')}
        description={t(
          'In dit scherm keur je de uploads van de klant af met een reden. De klant ontvangt vervolgens een e-mail met deze informatie.'
        )}
        isLoading={isUpdatingStatus}
        error={statusUpdateError}
        onSubmit={(reason) => handleTaskDecline(reason)}
      />
    </>
  );
};

import { createUserManager } from 'redux-oidc';

import Config from '../../config';
import { baseUrl } from './../../utils/baseUrl';

/**
 * @see https://github.com/IdentityModel/oidc-client-js/wiki
 */
const userManagerConfig = {
  metadataUrl: Config.oidcAuthority,
  authority: Config.oidcAuthority,
  loadUserInfo: false,
  redirect_uri: `${baseUrl}/callback`,
  response_type: 'code',
  client_id: Config.oidcClientId,
  scope: `openid profile https://${Config.oidcTenantName}.onmicrosoft.com/${Config.oidcScopeId}/TRA.Api.Access`,
  post_logout_redirect_uri: `${baseUrl}/logged-off`,
  // openid for id token
  // profile for Name and Email
  // oidcAudience from configuration for usage of the API
  // scope: `openid profile ${Config.oidcAudience}/user_impersonation https://storage.azure.com/user_impersonation`,
  silent_redirect_uri: `${baseUrl}/silent_renew`,
  // Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
  // The attempt is made as a result of the accessTokenExpiring event being raised.
  automaticSilentRenew: true,
  // Should OIDC protocol claims be removed from profile.
  filterProtocolClaims: true,
  silentRequestTimeout: 10000,
  // The number of seconds before an access token is to expire to raise the accessTokenExpiring event.
  accessTokenExpiringNotificationTime: 1000,
};

export const userManager = createUserManager(userManagerConfig);
export default userManager;

/* eslint-disable @typescript-eslint/no-empty-function */
import { ArrowSmDownIcon, UploadIcon, XIcon } from '@heroicons/react/outline';
import { XCircleIcon } from '@heroicons/react/outline';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { bytesToString } from 'utils/fileHelpers';
import { getBase64 } from 'utils/fileUploads';

import { RootState } from '../../app/rootReducer';
import { hasAdminAccess } from '../auth/Roles';
import { useUploadDataForUploadTask } from '../services/api';
import { UploadTask, UploadTaskFileUploadDto } from '../services/apiGenerated';
import { PanelBase } from './components/PanelBase';
import { PanelContents } from './components/PanelContents';
import { PanelErrorFooter } from './components/PanelError';

interface PanelProps {
  isOpened: boolean;
  onClose: () => void;
  uploadTask?: UploadTask;
  error?: SerializedError | FetchBaseQueryError | undefined;
}

export const UploadTaskCustomerPanel: React.FC<PanelProps> = ({ isOpened, onClose, uploadTask }: PanelProps) => {
  const onSliderClosed = () => {
    if (!isLoading) {
      onClose();
    }
  };
  const { t } = useTranslation();
  const [error, setError] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
    },
    [uploadedFiles]
  );
  const { user } = useSelector((state: RootState) => state.auth);
  const isAdministrator = hasAdminAccess(user);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: !isAdministrator ? '.csv, .xls, .xlsx, .xlsm, .xlsb, .txt' : '.txt, .csv',
  });

  const removeFile = (file: File) => () => {
    const newFiles = [...uploadedFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setUploadedFiles(newFiles);
  };
  const [uploadFilesForTask, { isLoading: isUploading, error: isUploadError }] = useUploadDataForUploadTask();

  const submitFiles = async () => {
    if (!uploadedFiles.length) {
      setError(t('Er zijn geen bestanden geselecteerd! Kies een bestand om te uploaden.'));
      return;
    }
    setIsLoading(true);

    // 1 create model:
    const payload: UploadTaskFileUploadDto[] = await Promise.all(
      uploadedFiles.map(async (file) => {
        return {
          taskId: uploadTask?.id,
          filename: file.name,
          fileContents: (await getBase64(file, true)) as string,
          fileExtension: file.name.split('.').pop(),
          fileSize: file.size,
        };
      })
    );
    uploadFilesForTask({ id: uploadTask?.id ?? 0, body: payload })
      .unwrap()
      .then(() => {
        onSliderClosed();
        setTimeout(() => {
          setError(undefined);
          setIsLoading(false);
          setUploadedFiles([]);
        }, 500);
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        console.log('error during saving file', rejectedValueOrSerializedError);
        setIsLoading(false);
      });
  };
  if (uploadTask === undefined) return <></>;

  return (
    <PanelBase isOpened={isOpened}>
      <div className='flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl'>
        <PanelContents
          title={uploadTask?.reportName ?? 'Loading...'}
          description={t('U kunt hieronder bestanden selecteren om te uploaden')}
          onSliderClosed={onSliderClosed}
        >
          <div>
            <p className='pb-4 text-sm'>{uploadTask.description}</p>
            {uploadTask.processingError && (
              <div className='p-4 mb-4 rounded-md bg-red-50'>
                <div className='flex'>
                  <div className='flex-shrink-0'>
                    <XCircleIcon className='w-5 h-5 text-red-400' aria-hidden='true' />
                  </div>
                  <div className='ml-3'>
                    <h3 className='text-sm font-medium text-red-800'>Fout bij controle van aangeleverde data:</h3>
                    <div className='mt-2 text-sm text-red-700'>
                      <ul className='pl-5 space-y-1 list-disc'>
                        <li>{uploadTask.processingError}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              {...getRootProps({ className: 'dropzone' })}
              className='flex flex-col items-center justify-center h-32 p-4 border-4 border-yellow-500 border-dashed'
            >
              <input {...getInputProps()} />
              <UploadIcon className='w-10 h-10 mb-4 text-yellow-800' />
              <p className='text-xs italic text-gray-400'>
                {t('Klik hier om bestanden te selecteren, of sleep ze hier naartoe')}
              </p>
            </div>
            <div className='relative my-4'>
              <div className='absolute inset-0 flex items-center' aria-hidden='true'>
                <div className='w-full border-t border-gray-300' />
              </div>
              <div className='relative flex justify-center'>
                <span className='px-2 text-gray-500 bg-white'>
                  <ArrowSmDownIcon className='w-5 h-5 text-gray-500' />
                </span>
              </div>
            </div>
            <div>
              <h4 className='font-semibold'>{t('Geselecteerde data:')}</h4>
              {uploadedFiles.map((file, idx) => (
                <div key={idx} className='flex items-start justify-between mt-4'>
                  <div>
                    <h2 className='text-sm font-medium text-gray-900'>
                      <span className='sr-only'>Details for </span>
                      {file.name}
                    </h2>
                    <p className='text-sm font-medium text-gray-500'>{bytesToString(file.size)}</p>
                  </div>
                  <button
                    type='button'
                    className={`flex items-center justify-center w-8 h-8 ml-4 text-gray-400 bg-white rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-500 ${
                      isLoading ? 'cursor-not-allowed' : ''
                    }`}
                    onClick={removeFile(file)}
                    disabled={isLoading}
                  >
                    <XIcon className='w-6 h-6' aria-hidden='true' />
                    <span className='sr-only'>Remove</span>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </PanelContents>
        <PanelErrorFooter error={error ?? isUploadError} />
        <div className='flex justify-end flex-shrink-0 px-4 py-4'>
          {!isLoading && !isUploading && (
            <>
              <button
                type='button'
                disabled={isLoading}
                className='px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                onClick={onSliderClosed}
              >
                {t('Annuleren')}
              </button>
              <button
                disabled={isLoading}
                type='button'
                onClick={() => submitFiles()}
                className='inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white bg-yellow-600 border border-transparent rounded-md shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
              >
                {t('Opslaan')}
              </button>
            </>
          )}
          {(isLoading || isUploading) && (
            <span className='inline-flex rounded-md shadow-sm'>
              <button
                type='button'
                className='inline-flex items-center px-4 py-2 text-sm font-medium leading-6 text-white transition duration-150 ease-in-out bg-yellow-700 border border-transparent rounded-md cursor-not-allowed '
                disabled={true}
              >
                <svg
                  className='w-5 h-5 mr-3 -ml-1 text-white animate-spin'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                  ></path>
                </svg>
              </button>
            </span>
          )}
        </div>
      </div>
    </PanelBase>
  );
};

import { useParams } from 'react-router-dom';

import userManager from './userManager';

/**
 * This component is really a javascript function mascerading as a react component.
 * This is needed, so the react router can "render" the user into the login process.
 *
 * When redirecting the users to the auth-provider, the redirect URL (of the page the user came from)
 * is passed on as a param for easy returns.
 * @returns empty component
 */
const LoginPage = () => {
  const params = useParams();
  userManager
    .signinRedirect({
      state: params.redirectTo,
    })
    .then(() => {
      return <></>;
    });

  return <></>;
};

export default LoginPage;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEn from './en/translation.json';
import translationNl from './nl/translation.json';

export const resources = {
  en: { translation: translationEn },
  nl: { translation: translationNl },
} as const;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'nl',
    fallbackLng: 'en',
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: ['en', 'nl'],
    // debug: true,
    keySeparator: false,
    nsSeparator: false,
  });

export default i18n;

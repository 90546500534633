/* eslint-disable @typescript-eslint/no-empty-function */
import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { PropsWithChildren } from 'react';

interface PanelProps {
  title: string;
  description: string;
  onSliderClosed: () => void;
}

export const PanelContents: React.FC<PanelProps> = ({
  title,
  description,
  onSliderClosed,
  children,
}: PropsWithChildren<PanelProps>) => (
  <div className='flex-1 h-0 overflow-y-auto'>
    <div className='px-4 py-6 bg-yellow-700 sm:px-6'>
      <div className='flex items-center justify-between'>
        <Dialog.Title className='text-lg font-medium text-white'>{title}</Dialog.Title>
        <div className='flex items-center ml-3 h-7'>
          <button
            type='button'
            className='text-yellow-200 bg-yellow-700 rounded-md hover:text-white focus:outline-none focus:ring-2 focus:ring-white'
            onClick={() => onSliderClosed()}
          >
            <XIcon className='w-6 h-6' aria-hidden='true' />
          </button>
        </div>
      </div>
      <div className='mt-1'>
        <p className='text-sm text-yellow-300'>{description}</p>
      </div>
    </div>
    <div className='flex flex-col justify-between flex-1'>
      <div className='px-4 divide-y divide-gray-200 sm:px-6'>
        <div className='pt-6 pb-5 space-y-6'>{children}</div>
      </div>
    </div>
  </div>
);

import { SearchIcon } from '@heroicons/react/solid';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface GlobalFilterProps {
  filter: string;
  setFilter: (newFilter: string) => void;
}
export const TableGlobalFilter = ({ filter, setFilter }: GlobalFilterProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className='relative mt-1 rounded-md shadow-sm'>
        <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
          <SearchIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
        </div>
        <input
          type='text'
          id='tablefilter'
          className='block w-full pl-10 border-gray-300 rounded-md focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm'
          placeholder={t('Zoeken')}
          defaultValue={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
    </div>
  );
};

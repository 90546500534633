/* eslint-disable @typescript-eslint/no-empty-function */
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import i18n from '../../i18n/config';
import { PanelBase } from './components/PanelBase';
import { PanelContents } from './components/PanelContents';
import { PanelErrorFooter } from './components/PanelError';
import { PanelSubmit } from './components/PanelSubmit';

const validationSchema = yup.object().shape({
  reason: yup.string().required(i18n.t('Dit veld is verplicht')),
});

interface PanelProps {
  isOpened: boolean;
  onClose: () => void;
  onSubmit: (reason: string) => void;
  title: string;
  description: string;
  isLoading: boolean;
  error?: SerializedError | FetchBaseQueryError | undefined;
}

export const UploadTaskDeclinePanel: React.FC<PanelProps> = ({
  isOpened,
  onClose,
  onSubmit,
  title,
  description,
  isLoading,
  error,
}: PanelProps) => {
  const onSliderClosed = () => {
    onClose();
  };
  const { t } = useTranslation();
  return (
    <PanelBase isOpened={isOpened}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          reason: '',
        }}
        onSubmit={(values) => {
          onSubmit(values.reason);
        }}
      >
        {({ errors, touched }) => (
          <Form className='flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl'>
            <PanelContents title={title} description={description} onSliderClosed={onSliderClosed}>
              <div>
                <label htmlFor='description' className='block text-sm font-medium text-gray-900'>
                  {t('Reden afwijzing (zichtbaar voor klant)')}
                </label>
                <div className='mt-1'>
                  <Field
                    component='textarea'
                    type='text'
                    autoComplete='false'
                    spellCheck={false}
                    name='reason'
                    id='reason'
                    rows={3}
                    className={`h-40 block w-full border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-yellow-500 focus:border-yellow-500 ${
                      errors.reason && touched.reason ? 'border-red-500' : ''
                    }`}
                  />
                  {errors.reason && touched.reason && (
                    <span className='text-xs italic text-yellow-800'>{errors.reason}</span>
                  )}
                </div>
              </div>
            </PanelContents>
            <PanelErrorFooter error={error} />
            <PanelSubmit isLoading={isLoading} onSliderClosed={onSliderClosed} />
          </Form>
        )}
      </Formik>
    </PanelBase>
  );
};

/* This example requires Tailwind CSS v2.0+ */
import { Menu, Transition } from '@headlessui/react';
import { TagIcon, UploadIcon, ChartSquareBarIcon } from '@heroicons/react/outline';
import ViewGridAddIcon from '@heroicons/react/outline/ViewGridAddIcon';
import { ChevronDownIcon, ExclamationIcon, UserAddIcon } from '@heroicons/react/solid';
import { Modal } from 'features/generic/Modal';
import { OrganizationTabPage } from 'features/pages/management/AdminOrganizationPage';
import { OrganisationUpsertPanel } from 'features/panels/OrganisationUpsertPanel';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { twClassNames } from '../../utils/twClassNames';
import { DepartmentUpsertPanel } from '../panels/DepartmentUpsertPanel';
import { EmployeeUpsertPanel } from '../panels/EmployeeUpsertPanel';
import { ReportUpsertPanel } from '../panels/ReportUpsertPanel';
import { UploadTaskUpsertPanel } from '../panels/UploadTaskUpsertPanel';
import {
  useCreateDepartment,
  useUpdateOrganizations,
  useCreateUploadTask,
  useCreateReport,
  useCreateEmployee,
} from '../services/api';
import {
  CreateOrganizationDto,
  Department,
  Organization,
  UploadTask,
  ReportInfoDto,
  CreateEmployeeDto,
} from '../services/apiGenerated';

export const OrganizationActionDropdown: React.FC<{
  organization: Organization;
  onDelete: () => void;
  onItemAdded: (page: OrganizationTabPage) => void;
}> = ({ organization, onDelete, onItemAdded }) => {
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const { t } = useTranslation();

  const [createDeptOpened, setCreateDeptOpened] = useState(false);
  const [createUploadTaskOpened, setCreateUploadTaskOpened] = useState(false);
  const [createEmployeeOpened, setCreateEmployeeOpened] = useState(false);
  const [createReportOpened, setCreateReportOpened] = useState(false);
  const [editPanelOpened, setEditPanelOpened] = useState(false);

  const [updateOrganisation, { isLoading: isUpdatingOrganization, error: updateOrganizationError }] =
    useUpdateOrganizations();

  const [saveDepartment, { isLoading: isCreatingDepartment, error: createDepartmentError }] = useCreateDepartment();
  const [saveUploadTask, { isLoading: isCreatingUploadTask, error: createUploadTaskError }] = useCreateUploadTask();
  const [saveReportTask, { isLoading: isCreatingReport, error: createReportError }] = useCreateReport();
  const [saveEmployee, { isLoading: isCreatingEmployee, error: createEmployeeError }] = useCreateEmployee();

  const handleDeleteOrganization = () => {
    setDeleteModalOpened(false);
    onDelete();
  };
  const handleUpdate = (dto: CreateOrganizationDto) => {
    updateOrganisation({
      id: organization.id as number,
      updateOrganizationDto: {
        ...dto,
      },
    });
  };
  const handleCreateDepartment = (dto: Partial<Department>) => {
    saveDepartment({
      department: {
        organizationId: organization.id as number,
        name: dto.name,
        isActive: dto.isActive,
      },
    })
      .unwrap()
      .then(() => {
        setCreateDeptOpened(false);
        onItemAdded('departments');
      });
  };

  const handleCreateUploadTask = (dto: Partial<UploadTask>) => {
    saveUploadTask({
      uploadTask: {
        organizationId: organization.id as number,
        ...dto,
      },
    })
      .unwrap()
      .then(() => {
        setCreateUploadTaskOpened(false);
        onItemAdded('uploadTasks');
      });
  };

  const handleCreateReport = (dto: ReportInfoDto) => {
    saveReportTask({
      createReportDto: dto,
    })
      .unwrap()
      .then(() => {
        setCreateReportOpened(false);
        onItemAdded('reports');
      });
  };

  const handleCreateEmployee = (dto: CreateEmployeeDto) => {
    saveEmployee({
      createEmployeeDto: dto,
    })
      .unwrap()
      .then(() => {
        setCreateEmployeeOpened(false);
        onItemAdded('employees');
      });
  };

  return (
    <>
      <Menu as='div' className='relative inline-block text-left'>
        {({ open }) => (
          <>
            <div>
              <Menu.Button className='inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-yellow-600 border border-yellow-300 rounded-md shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-yellow-500'>
                Acties
                <ChevronDownIcon className='w-5 h-5 ml-2 -mr-1' aria-hidden='true' />
              </Menu.Button>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items
                static
                className='absolute right-0 z-50 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
              >
                <div className='py-1'>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => setCreateDeptOpened(true)}
                        className={twClassNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'group flex items-center px-4 py-2 text-sm w-full'
                        )}
                      >
                        <ViewGridAddIcon
                          className='w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500'
                          aria-hidden='true'
                        />
                        {t('Nieuwe afdeling')}
                      </button>
                    )}
                  </Menu.Item>
                </div>
                <div className='py-1'>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => setCreateEmployeeOpened(true)}
                        className={twClassNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'group flex items-center px-4 py-2 text-sm w-full'
                        )}
                      >
                        <UserAddIcon
                          className='w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500'
                          aria-hidden='true'
                        />
                        {t('Nieuwe gebruiker')}
                      </button>
                    )}
                  </Menu.Item>
                </div>
                {organization.uploadType !== 'None' && (
                  <div className='py-1'>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => setCreateUploadTaskOpened(true)}
                          className={twClassNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'group flex items-center px-4 py-2 text-sm w-full'
                          )}
                        >
                          <UploadIcon
                            className='w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500'
                            aria-hidden='true'
                          />
                          {t('Nieuwe uploadtaak')}
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                )}
                <div className='py-1'>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => setCreateReportOpened(true)}
                        className={twClassNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'group flex items-center px-4 py-2 text-sm w-full'
                        )}
                      >
                        <ChartSquareBarIcon
                          className='w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500'
                          aria-hidden='true'
                        />
                        {t('Nieuwe rapportage')}
                      </button>
                    )}
                  </Menu.Item>
                </div>
                <div className='py-1'>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => setEditPanelOpened(true)}
                        className={twClassNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'group flex items-center px-4 py-2 text-sm w-full'
                        )}
                      >
                        <TagIcon className='w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500' aria-hidden='true' />
                        {t('Organisatie wijzigen')}
                      </button>
                    )}
                  </Menu.Item>
                </div>
                <div className='py-1'>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => setDeleteModalOpened(true)}
                        className={twClassNames(
                          active ? 'bg-gray-100 text-red-900' : 'text-red-700',
                          'group flex items-center px-4 py-2 text-sm w-full'
                        )}
                      >
                        <ExclamationIcon
                          className='w-5 h-5 mr-3 text-red-500 group-hover:text-red-600'
                          aria-hidden='true'
                        />
                        {t('Organisatie verwijderen')}
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      <Modal
        isOpened={deleteModalOpened}
        onClose={() => setDeleteModalOpened(false)}
        type='error'
        title={t('Organisatie verwijderen')}
        description={t(
          'Weet je zeker dat je deze organisatie wilt verwijderen? Alle gebruikers, rapportages en andere zaken zullen tevens verwijderd worden.'
        )}
        cancelButtonText={t('Annuleren')}
        actionButtonText={t('Verwijderen')}
        onAction={handleDeleteOrganization}
      />
      <OrganisationUpsertPanel
        title={t('Organisatie wijzigen')}
        description={t('Wijzig de organisatie door hieronder de gegevens aan te passen')}
        isOpened={editPanelOpened}
        onClose={() => {
          setEditPanelOpened(false);
        }}
        onSubmit={(dto: CreateOrganizationDto) => {
          handleUpdate(dto);
          setEditPanelOpened(false);
        }}
        organization={organization}
        isLoading={isUpdatingOrganization}
        error={updateOrganizationError}
      />
      <DepartmentUpsertPanel
        title={t('Nieuwe afdeling')}
        description={t(
          'Creeër een nieuwe afdeling. Na het aanmaken is het mogelijk om rapportages en/of klantgebruikers te koppelen'
        )}
        isOpened={createDeptOpened}
        onClose={() => {
          setCreateDeptOpened(false);
        }}
        onSubmit={(department: Partial<Department>) => {
          handleCreateDepartment(department);
        }}
        isLoading={isCreatingDepartment}
        error={createDepartmentError}
      />
      <UploadTaskUpsertPanel
        title={t('Nieuwe uploadtaak')}
        description={t(
          'Creeër een nieuwe uploadtaak voor klanten, zodat klanten informatie kunnen aanleveren voor hun rapportage'
        )}
        isOpened={createUploadTaskOpened}
        onClose={() => {
          setCreateUploadTaskOpened(false);
        }}
        onSubmit={(uploadTask: Partial<UploadTask>) => {
          handleCreateUploadTask(uploadTask);
        }}
        isLoading={isCreatingUploadTask}
        error={createUploadTaskError}
      />
      <ReportUpsertPanel
        title={t('Nieuwe rapportage')}
        description={t('Creeër een nieuwe rapportage voor klanten, zodat klanten deze kunnen downloaden of inzien.')}
        isOpened={createReportOpened}
        onClose={() => {
          setCreateReportOpened(false);
        }}
        orgId={organization.id as number}
        onSubmit={(report: ReportInfoDto) => {
          handleCreateReport(report);
        }}
        isLoading={isCreatingReport}
        error={createReportError}
      />
      <EmployeeUpsertPanel
        title={t('Nieuwe gebruiker')}
        description={t('Creeër een nieuwe gebruiker die in kan loggen in de portaal')}
        isOpened={createEmployeeOpened}
        onClose={() => {
          setCreateEmployeeOpened(false);
        }}
        orgId={organization.id as number}
        onSubmit={(employee: CreateEmployeeDto) => {
          handleCreateEmployee(employee);
        }}
        isLoading={isCreatingEmployee}
        error={createEmployeeError}
      />
    </>
  );
};

/* eslint-disable @typescript-eslint/no-empty-function */
import { Listbox, Switch, Transition } from '@headlessui/react';
import { RadioGroup } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/outline';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { convertToRaw, EditorState, ContentState, convertFromRaw } from 'draft-js';
import { SelectCombo } from 'features/types';
import { Formik, Form, Field } from 'formik';
import { Fragment, useRef, useState } from 'react';
import { Editor, RawDraftContentState } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import * as yup from 'yup';

import excelLogo from '../../assets/images/icons/excel.svg';
import pdfLogo from '../../assets/images/icons/pdf.svg';
import powerBILogo from '../../assets/images/icons/powerbi.svg';
import i18n from '../../i18n/config';
import { getBase64 } from '../../utils/fileUploads';
import { isJson } from '../../utils/jsonHelper';
import { twClassNames } from '../../utils/twClassNames';
import { useGetDepartmentByOrganization, useGetPowerBIReports } from '../services/api';
import { ReportType, OrganizationReport } from '../services/apiGenerated';
import { PanelBase } from './components/PanelBase';
import { PanelContents } from './components/PanelContents';
import { PanelErrorFooter } from './components/PanelError';
import { PanelSubmit } from './components/PanelSubmit';

const validationSchema: yup.SchemaOf<OrganizationReportDto> = yup.object().shape({
  name: yup
    .string()
    .max(50, i18n.t('Naam mag minimaal 50 karakters bevatten'))
    .required(i18n.t('Dit veld is verplicht')),
  description: yup.string(),
  departmentIds: yup
    .array()
    .of(yup.number().required())
    .test({
      message: i18n.t('U moet één of meer afdelingen selecteren').toString(),
      test: (arr) => arr !== undefined && arr.length > 0,
    })
    .strict()
    .required(),
  isActive: yup.bool().required(),
  type: yup.mixed().oneOf(['PowerBI', 'Excel', 'PDF']).required(),
  powerbiReportId: yup.string().when('type', {
    is: (val: ReportType) => val === 'PowerBI', // alternatively: (val) => val == true
    then: yup.string().required(i18n.t('Het is verplicht een PowerBI Rapport te kiezen')),
  }),
  uploadedFileName: yup.string().when('type', {
    is: (val: ReportType) => val !== 'PowerBI', // alternatively: (val) => val == true
    then: yup.string().required(i18n.t('Het is verplicht een bestand te kiezen')),
  }),
  uploadedFileContents: yup.string(),
  organizationId: yup.number().required(),
});

interface PanelProps {
  isOpened: boolean;
  onClose: () => void;
  onSubmit: (dto: Partial<OrganizationReportDto>) => void;
  title: string;
  description: string;
  orgId: number;
  report?: OrganizationReport;
  isLoading: boolean;
  error?: SerializedError | FetchBaseQueryError | undefined;
}

interface OrganizationReportDto {
  name: string;
  description?: string;
  isActive: boolean;
  type: ReportType;
  departmentIds: number[];
  powerbiReportId?: string;
  uploadedFileName?: string;
  uploadedFileContents?: string;
  organizationId: number;
}

const reports = [
  {
    name: i18n.t('Excel'),
    description: i18n.t('Downloadbare Excel-rapportage'),
    icon: excelLogo,
    type: 'Excel' as ReportType,
  },
  {
    name: i18n.t('PDF'),
    description: i18n.t('Downloadbare PDF-rapportage'),
    icon: pdfLogo,
    type: 'PDF' as ReportType,
  },
  {
    name: i18n.t('PowerBI'),
    description: i18n.t('Interactieve PowerBI rapportage'),
    icon: powerBILogo,
    type: 'PowerBI' as ReportType,
  },
];

export const ReportUpsertPanel: React.FC<PanelProps> = ({
  isOpened,
  onClose,
  onSubmit,
  title,
  description,
  report,
  orgId,
  isLoading,
  error,
}: PanelProps) => {
  const onSliderClosed = () => {
    onClose();
  };
  const { t } = useTranslation();

  const animatedComponents = makeAnimated();
  const { data: departmentData } = useGetDepartmentByOrganization({ organizationId: orgId });
  const { data: powerBIReportsData } = useGetPowerBIReports();
  const [editorState, setEditorState] = useState<EditorState>(() => {
    if (!report?.description) {
      return EditorState.createEmpty();
    }
    return !isJson(report?.description)
      ? EditorState.createWithContent(ContentState.createFromText(report?.description))
      : EditorState.createWithContent(convertFromRaw(JSON.parse(report?.description) as RawDraftContentState));
  });

  const updateTextDescription = async (state: EditorState) => {
    await setEditorState(state);
  };

  const departments: SelectCombo[] = departmentData
    ? departmentData
        .filter((x) => x.id)
        .map((d) => {
          return {
            label: d.name ?? '',
            value: d.id ?? '',
          };
        })
    : [];

  const powerBIReports = powerBIReportsData
    ? powerBIReportsData
        .filter((x) => x.id)
        .map((d) => {
          return {
            name: d.name,
            value: d.id,
          };
        })
    : [];

  // Only add the "choose" when this is a create action.
  if (!report || !report.reportSource) {
    powerBIReports.unshift({
      name: '-- Kies rapportage --',
      value: '',
    });
  }
  const selectedDepartmentValues: SelectCombo[] = [];
  const departmentIds: number[] = [];
  if (report?.departments) {
    report?.departments
      ?.map((d) => d.id)
      .forEach((i) => {
        if (i) {
          departmentIds.push(i);
          const found = departments?.find((x) => x && x.value === i);
          if (found) {
            selectedDepartmentValues.push(found);
          }
        }
      });
  }
  const fileUpload = useRef(null);
  return (
    <PanelBase isOpened={isOpened}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          name: report?.name ?? '',
          isActive: report?.isActive ?? false,
          type: report?.type ?? ('Excel' as ReportType),
          departmentIds: departmentIds,
          powerbiReportId:
            report?.type !== undefined && report.type === 'PowerBI' ? (report.reportSource as string) : ('' as string),
          uploadedFileContents: report?.type !== undefined && report.type !== 'PowerBI' ? '(Huidig bestand)' : '',
          uploadedFileName: report?.type !== undefined && report.type !== 'PowerBI' ? '(Huidig bestand)' : '',
          organizationId: orgId,
        }}
        onSubmit={(values) => {
          onSubmit({
            name: values.name,
            description: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
            isActive: values.isActive,
            type: values.type,
            departmentIds: values.departmentIds,
            powerbiReportId: values.type === 'PowerBI' ? values.powerbiReportId : '',
            uploadedFileName:
              values.type !== 'PowerBI' && values.uploadedFileName !== '(Huidig bestand)'
                ? values.uploadedFileName
                : '',
            uploadedFileContents:
              values.type !== 'PowerBI' && values.uploadedFileName !== '(Huidig bestand)'
                ? values.uploadedFileContents
                : '',
            organizationId: orgId,
          });
        }}
      >
        {({ errors, touched, values, setValues }) => (
          <Form className='flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl'>
            <PanelContents title={title} description={description} onSliderClosed={onSliderClosed}>
              <div>
                <label htmlFor='name' className='block text-sm font-medium text-gray-900'>
                  {t('Rapportage naam')}
                </label>
                <div className='mt-1'>
                  <Field
                    autoComplete='false'
                    spellCheck={false}
                    type='text'
                    name='name'
                    id='name'
                    className={`block w-full border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-yellow-500 focus:border-yellow-500 ${
                      errors.name && touched.name ? 'border-red-500' : ''
                    }`}
                  />
                  {errors.name && touched.name && <span className='text-xs italic text-yellow-800'>{errors.name}</span>}
                </div>
              </div>
              <div>
                <label htmlFor='description' className='block text-sm font-medium text-gray-900'>
                  {t('Inleidende tekst bij rapportage')}
                </label>
                <div className='mt-1'>
                  {/* <Field
                    autoComplete='false'
                    spellCheck={false}
                    component='textarea'
                    type='text'
                    name='description'
                    id='description'
                    rows={3}
                    className={`h-20 block w-full border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-yellow-500 focus:border-yellow-500 ${
                      errors.description && touched.description ? 'border-red-500' : ''
                    }`}
                  /> */}
                  <Editor
                    editorState={editorState}
                    toolbarClassName='toolbarClassName'
                    wrapperClassName='wrapperClassName'
                    editorClassName='editorClassName'
                    onEditorStateChange={updateTextDescription}
                  />
                </div>
              </div>
              <div>
                <RadioGroup
                  value={reports.find((x) => x.type === values.type)}
                  onChange={(e) => {
                    setValues({ ...values, type: e?.type ?? 'PowerBI' });
                  }}
                >
                  <RadioGroup.Label className='sr-only'>Rapport type</RadioGroup.Label>

                  <div className='flex flex-row space-x-4 '>
                    {reports.map((report) => (
                      <RadioGroup.Option
                        key={report.name}
                        value={report}
                        className={({ active }) =>
                          twClassNames(
                            active ? 'ring-1 ring-offset-2 ring-yellow-500 bg-yellow-50' : '',
                            'relative flex-grow rounded-lg border border-gray-300 bg-white shadow-sm px-3 py-4 cursor-pointer hover:border-gray-400 flex focus:outline-none'
                          )
                        }
                      >
                        {({ checked }) => (
                          <>
                            <RadioGroup.Description as='div' className='flex mt-0 mr-2 text-sm sm:block'>
                              <div className='font-medium text-gray-900'>
                                <img src={report.icon} className='w-8 h-8 ' />
                              </div>
                            </RadioGroup.Description>
                            <div className='flex items-center'>
                              <div className='text-sm'>
                                <RadioGroup.Label as='p' className='font-medium text-gray-900'>
                                  {report.name}
                                </RadioGroup.Label>
                              </div>
                            </div>

                            <div
                              className={twClassNames(
                                checked ? 'border-yellow-500' : 'border-transparent',
                                'absolute -inset-px rounded-lg border-2 pointer-events-none'
                              )}
                              aria-hidden='true'
                            />
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div>
              {values.type === 'PowerBI' && (
                <div>
                  <label htmlFor='name' className='block text-sm font-medium text-gray-900'>
                    {t('Kies rapportage')}
                  </label>
                  <div className='mt-1'>
                    <Listbox
                      value={values.powerbiReportId}
                      onChange={(e) => {
                        if (powerBIReports.find((x) => !x.value)) {
                          powerBIReports.shift();
                        }
                        setValues({ ...values, powerbiReportId: e });
                      }}
                    >
                      <div className='relative z-50 mt-1'>
                        <Listbox.Button className='relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-yellow-300 focus-visible:ring-offset-2 focus-visible:border-yellow-500 sm:text-sm'>
                          <span className='block truncate'>
                            {powerBIReports &&
                              (powerBIReports.find((x) => x.value === values.powerbiReportId)?.name ?? '')}
                          </span>
                          <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                            <SelectorIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave='transition ease-in duration-100'
                          leaveFrom='opacity-100'
                          leaveTo='opacity-0'
                        >
                          <Listbox.Options className='absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                            {powerBIReports.map((pbi) => (
                              <Listbox.Option
                                key={pbi.value}
                                className={({ active }) =>
                                  `${active ? 'text-yellow-900 bg-amber-100' : 'text-gray-900'}
                           cursor-default select-none relative py-2 pl-10 pr-4`
                                }
                                value={pbi.value}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span className={`${selected ? 'font-medium' : 'font-normal'} block truncate`}>
                                      {pbi.name}
                                    </span>
                                    {selected ? (
                                      <span
                                        className={`${active || selected ? 'text-yellow-600' : 'text-black'}
                                 absolute inset-y-0 left-0 flex items-center pl-3`}
                                      >
                                        <CheckIcon className='w-5 h-5' aria-hidden='true' />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>

                    {errors.powerbiReportId && touched.powerbiReportId && (
                      <span className='text-xs italic text-yellow-800'>{errors.powerbiReportId}</span>
                    )}
                  </div>
                </div>
              )}
              {values.type !== 'PowerBI' && (
                <div>
                  <label htmlFor='uploadedFileName' className='block text-sm font-medium text-gray-900'>
                    {t('Bestand kiezen')}
                  </label>
                  <div className='flex items-center mt-1'>
                    <input
                      type='file'
                      ref={fileUpload}
                      className='hidden'
                      name='uploadedFileName'
                      onChange={async (e) => {
                        if (e?.target?.files && e?.target?.files[0]) {
                          const file = e.target.files[0];
                          const base64 = await getBase64(file, true);
                          setValues({
                            ...values,
                            uploadedFileName: file.name,
                            uploadedFileContents: base64,
                          });
                        }
                      }}
                      accept={values.type === 'Excel' ? '.xls,.xlsx,.xlsm' : '.pdf'}
                    />
                    <button
                      type='button'
                      className='px-3 py-2 text-sm font-medium leading-4 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                      onClick={() => (fileUpload.current as unknown as HTMLInputElement)?.click()}
                    >
                      {t('Selecteer')}
                    </button>
                    <p className='ml-2 text-sm'>{values.uploadedFileName ?? t('Nog geen bestand gekozen')}</p>
                  </div>
                  {errors.uploadedFileName && touched.uploadedFileName && (
                    <span className='text-xs italic text-yellow-800'>{errors.uploadedFileName}</span>
                  )}
                </div>
              )}
              <div>
                <label htmlFor='departmentIds' className='block text-sm font-medium text-gray-900'>
                  {t('Kies afdelingen')}
                </label>
                <div className='mt-1'>
                  <Select
                    defaultValue={selectedDepartmentValues}
                    isMulti
                    components={animatedComponents}
                    name='departmentIds'
                    options={departments}
                    closeMenuOnSelect={false}
                    className='text-sm text-gray-900 border-gray-yellow'
                    onChange={(e) => {
                      setValues({ ...values, departmentIds: e?.map((x: SelectCombo) => x.value) } ?? []);
                    }}
                  />
                  {errors.departmentIds && touched.departmentIds && (
                    <span className='text-xs italic text-yellow-800'>{errors.departmentIds}</span>
                  )}
                </div>
              </div>
              <div className='flex flex-row items-center'>
                <Switch
                  checked={values.isActive}
                  onChange={(value) =>
                    setValues({
                      ...values,
                      isActive: value,
                    })
                  }
                  className={`${
                    values.isActive ? 'bg-yellow-600' : 'bg-gray-200'
                  } relative inline-flex items-center h-6 rounded-full w-11`}
                >
                  <span
                    className={`${
                      values.isActive ? 'translate-x-6' : 'translate-x-1'
                    } inline-block w-4 h-4 transform bg-white rounded-full transition ease-in-out duration-200`}
                  />
                </Switch>
                <label htmlFor='isActive' className='ml-4 text-sm font-medium text-gray-900'>
                  {t('Klanten hebben toegang tot deze rapportage')}
                </label>
              </div>
            </PanelContents>
            <PanelErrorFooter error={error} />
            <PanelSubmit isLoading={isLoading} onSliderClosed={onSliderClosed} />
          </Form>
        )}
      </Formik>
    </PanelBase>
  );
};

import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { base64ToBlob } from 'utils/fileHelpers';

import { useGetFileEmbedParams } from '../services/api';

export const ReportPDFPreview: React.FC<{ reportId: string; reportName: string }> = ({ reportId, reportName }) => {
  const { data, isLoading } = useGetFileEmbedParams({ id: reportId });
  const { t } = useTranslation();
  const downloadFile = async () => {
    if (!data?.fileContents) return;
    const blob = await base64ToBlob(data.fileContents, 'application/pdf');
    saveAs(blob, `${reportName}.pdf`);
    console.log('OK');
  };
  if (!isLoading && data) {
    return (
      <div className='h-[2048px] w-full'>
        <button
          onClick={() => downloadFile()}
          type='button'
          className='inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-white bg-yellow-600 border border-transparent rounded-md shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
        >
          {t('Rapportage downloaden')}
        </button>
      </div>
    );
  }

  return <></>;
};

/* eslint-disable react/jsx-key */
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Column } from 'react-table';

import { Table, TableCellActiveIcon, TableCellChevronRight, TableCellInactiveIcon } from '../generic/Table';
import { AdminEmployeeInfoDto } from '../services/apiGenerated';

export const AdminUserTable: React.FC<{
  data: AdminEmployeeInfoDto[] | undefined;
  isLoading: boolean;
  error?: FetchBaseQueryError | SerializedError;
}> = ({ data, isLoading, error }) => {
  const { t } = useTranslation();

  const onRowClick = (row: AdminEmployeeInfoDto) => {
    navigate(`/management/adminusers/${row.id}`);
  };

  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/ban-types
  const columns: Column<AdminEmployeeInfoDto>[] = [
    {
      Header: t('Voornaam').toString(),
      accessor: 'firstName',
    },
    {
      Header: t('Achternaam').toString(),
      accessor: 'lastName',
    },
    {
      Header: t('Gebruikersnaam').toString(),
      accessor: 'email',
    },
    {
      Header: t('Actief').toString(),
      accessor: (row) => (row?.isActive === true ? TableCellActiveIcon : TableCellInactiveIcon),
    },
    {
      Header: ' ',
      accessor: () => TableCellChevronRight,
    },
  ];

  return <Table columns={columns} data={data ?? []} onRowClick={onRowClick} isLoading={isLoading} error={error} />;
};

import { ContentState, convertFromRaw, EditorState } from 'draft-js';
import { Editor, RawDraftContentState } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';

import { isJson } from '../../utils/jsonHelper';
import { ReportPDFPreview } from '../reports/ReportPreviewPDF';
import { ReportPowerBIPreview } from '../reports/ReportPreviewPowerBI';
import { ReportXLSPreview } from '../reports/ReportPreviewXLS';
import { useGetReportByReportId } from '../services/api';

// Root Component to demonstrate usage of wrapper component
export const ReportPage = () => {
  const { id } = useParams();

  const validIdPassed = id !== undefined && id.length;

  // PowerBI Report object (to be received via callback)
  const { t } = useTranslation();
  // API end-point url to get embed config for a sample report
  const { data: report, isLoading, error } = useGetReportByReportId({ id: id?.toString() ?? '' });

  if (!validIdPassed) {
    return <p>Something went wrong (invalid id)</p>;
  }
  if (isLoading) {
    return (
      <div style={{ fontSize: 25 }}>
        <Skeleton count={5} />
      </div>
    );
  }
  if (error) {
    <p>Something went wrong: {JSON.stringify(error)}</p>;
  }
  const editorValue = !isJson(report?.description?.toString() ?? '')
    ? EditorState.createWithContent(ContentState.createFromText(report?.description?.toString() ?? ''))
    : EditorState.createWithContent(
        convertFromRaw(JSON.parse(report?.description?.toString() ?? '{}') as RawDraftContentState)
      );
  const hasContent = editorValue.getCurrentContent().getPlainText().length > 0;
  return (
    <div className='min-h-screen space-y-2'>
      <div className='flex flex-col h-full mt-1' style={{ height: 'calc(100vh - 0.55rem)' }}>
        <div className={`'flex flex-col px-2 space-y-4 max-w-7xl ${report?.type === 'PowerBI' ? 'hidden' : ''}`}>
          <h1 className='flex items-center justify-start text-3xl font-bold text-gray-900 uppercase '>
            <span className='tracking-tight'>
              {t('Rapportage')} - {report?.name}
            </span>
            <span className='flex-grow-0 w-20 h-1 ml-2 bg-black'></span>
          </h1>
          {hasContent && (
            <p className={`leading-relaxed tracking-tigh`}>
              <Editor
                toolbarHidden
                editorClassName='select-none'
                readOnly
                editorState={editorValue}
                onChange={() => {
                  return;
                }}
              />
            </p>
          )}
        </div>
        <div className={`px-2 ${report?.type === 'PowerBI' ? '-ml-1' : ''} flex flex-1 bg-white`}>
          {report?.type === 'PowerBI' && <ReportPowerBIPreview reportId={id} />}
          {report?.type === 'Excel' && <ReportXLSPreview reportId={id} reportName={report?.name ?? ''} />}
          {report?.type === 'PDF' && <ReportPDFPreview reportId={id} reportName={report?.name ?? ''} />}
        </div>
      </div>
    </div>
  );
};

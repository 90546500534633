import { OfficeBuildingIcon, ViewGridAddIcon } from '@heroicons/react/outline';
import { OrganizationsTable } from 'features/organizations/OrganizationsTable';
import { useCreateOrganizations } from 'features/services/api';
import { CreateOrganizationDto } from 'features/services/apiGenerated';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OrganisationUpsertPanel } from '../../panels/OrganisationUpsertPanel';

export const AdminOrganizationsOverviewPage = () => {
  const { t } = useTranslation();
  const [creationOpened, setCreationOpened] = useState(false);
  const [
    saveOrganization, // This is the mutation trigger
    { isLoading, error }, // This is the destructured mutation result
  ] = useCreateOrganizations();
  const handleCreation = (dto: CreateOrganizationDto) => {
    saveOrganization({
      createOrganizationDto: {
        name: dto.name,
        logoContents: dto.logoContents,
        isActive: dto.isActive,
      },
    });
  };
  return (
    <div>
      <div className='py-6 space-y-4'>
        <div className='flex flex-row justify-between px-4 mx-auto space-y-4 max-w-7xl sm:px-6 lg:px-8'>
          {/* <Breadcrumbs /> */}
          <h1 className='flex items-center justify-start space-x-2 text-3xl font-bold text-gray-900 uppercase'>
            <OfficeBuildingIcon className='w-8 h-8' />
            <span className='tracking-tight'>{t('Organisaties')}</span>
            <span className='flex-grow-0 w-20 h-1 bg-black'></span>
          </h1>
          <button
            onClick={() => setCreationOpened(true)}
            className='inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-yellow-600 border border-yellow-300 rounded-md shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-yellow-500'
          >
            Nieuwe organisatie
            <ViewGridAddIcon className='w-5 h-5 ml-2 -mr-1' aria-hidden='true' />
          </button>
        </div>

        <div className='px-4 mx-auto space-y-4 max-w-7xl sm:px-6 md:px-8'>
          <OrganizationsTable />
        </div>
      </div>
      <OrganisationUpsertPanel
        title={t('Nieuwe organisatie')}
        description={t('Vul onderstaand formulier in om een nieuwe organisatie aan te maken')}
        isOpened={creationOpened}
        onClose={() => {
          setCreationOpened(false);
        }}
        onSubmit={(dto: CreateOrganizationDto) => {
          handleCreation(dto);
          setCreationOpened(false);
        }}
        isLoading={isLoading}
        error={error}
      />
    </div>
  );
};

import { TrashIcon } from '@heroicons/react/solid';
import { RootState } from 'app/rootReducer';
import { userInRole } from 'features/auth/Roles';
import Button from 'features/generic/Button';
import { Modal } from 'features/generic/Modal';
import { useCreateDocument, useDeleteDocument, useDownloadDocument, useGetDocuments } from 'features/services/api';
import { CreateDocumentDto, DocumentDto, DocumentFile } from 'features/services/apiGenerated';
import saveAs from 'file-saver';
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { base64ToBlob, bytesToString, extensionToMimeType } from 'utils/fileHelpers';

import { OrganizationDocumentDropdown } from './OrganizationDocumentDropdown';

interface DocumentRowProps {
  document: DocumentDto;
  onNavigate: (document: DocumentDto) => void;
  onDownload: (document: DocumentDto) => void;
  onDelete: (document: DocumentDto) => void;
}

interface OrganizationDocumentsListProps {
  orgId?: number;
}

export const OrganizationDocumentsList: React.FC<OrganizationDocumentsListProps> = ({ orgId }) => {
  const { data, isLoading } = useGetDocuments({ organizationId: orgId });
  const [documents, setDocuments] = useState<DocumentDto[]>(
    data?.filter((document) => document.parentId === null) ?? []
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [history, setHistory] = useState<DocumentDto[][]>([]);
  const [level, setLevel] = useState(0);
  const [breadcrumbs, setBreadcrumbs] = useState<DocumentDto[]>([]); // Breadcrumbs state

  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const [deleteModalText, setDeleteModalText] = useState('');
  const [deletedDocument, setDeletedDocument] = useState<DocumentDto>();
  const [sortField, setSortField] = useState('name'); // Default sort by name
  const [sortDirection, setSortDirection] = useState('asc'); // 'asc' or 'desc'
  const [uploadFileFolder, { isLoading: isUploading, error: uploadingError }] = useCreateDocument();
  const [deleteFileFolder] = useDeleteDocument();
  const [triggerDownload, downloadResult] = useDownloadDocument();
  const [uploadFinished, setUploadFinished] = useState(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const sortedDocuments = useMemo(() => {
    return [...documents].sort((a, b) => {
      // Keep folders at the top
      if (a.isFolder && !b.isFolder) return -1;
      if (!a.isFolder && b.isFolder) return 1;

      // Sorting by name
      if (sortField === 'name') {
        if (!a.name || !b.name) return 0;
        if (a.name < b.name) return sortDirection === 'asc' ? -1 : 1;
        if (a.name > b.name) return sortDirection === 'asc' ? 1 : -1;
      }

      // Sorting by date
      if (sortField === 'creationDate') {
        if (!a.creationDate || !b.creationDate) return 0;
        const dateA = new Date(a.creationDate);
        const dateB = new Date(b.creationDate);
        return sortDirection === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      }

      return 0;
    });
  }, [documents, sortField, sortDirection]);

  // Adjust the useEffect hook that sets the initial top-level documents
  useEffect(() => {
    if (data) {
      const topLevelDocuments = data.filter((document) => document.parentId === null);
      setDocuments(topLevelDocuments);
    }
  }, [data]);

  const navigateToFolder = (document: DocumentDto) => {
    setHistory((prevHistory) => [...prevHistory, documents]);
    setDocuments(document.children || []);
    setLevel((prevLevel) => prevLevel + 1);
    setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, document]); // Update breadcrumbs
  };
  const navigateBack = () => {
    // Use the second-to-last entry in the history as the new document state
    // because the last entry is the current state before going back
    setHistory((prevHistory) => {
      if (prevHistory.length > 0) {
        const previousDocuments = prevHistory[prevHistory.length - 1] || [];
        setDocuments(previousDocuments);
        const newHistory = [...prevHistory];
        newHistory.pop(); // Remove the last entry to go back to the previous state
        return newHistory;
      } else {
        // If history is empty, reset to top-level documents
        setDocuments(data?.filter((document) => document.parentId === null) ?? []);
        return [];
      }
    });

    // Update the level and breadcrumbs to reflect going back
    setLevel((prevLevel) => (prevLevel > 0 ? prevLevel - 1 : 0));
    setBreadcrumbs((prevBreadcrumbs) => prevBreadcrumbs.slice(0, -1));
  };

  const setSortFieldAndDirection = (field: string) => {
    if (sortField === field) {
      // Toggle sort direction
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc'); // Default to ascending when changing sort field
    }
  };

  const uploadFiles = (uploadedFiles: CreateDocumentDto[]) => {
    console.log('uploading files', uploadedFiles);
    const parentId = breadcrumbs?.[breadcrumbs.length - 1]?.id ?? null;
    uploadedFiles.forEach((file) => {
      const toUpload: CreateDocumentDto = {
        ...file,
        isFolder: false,
        organizationId: orgId,
        parentId: parentId,
      };
      uploadFileFolder({
        createDocumentDto: toUpload,
      }).then((response) => {
        if ('data' in response) {
          const newFolder: DocumentDto = {
            name: file.filename,
            size: file.fileSize,
            creationDate: new Date().toISOString(),
            parentId: parentId,
            isFolder: false,
            id: response.data.id,
            children: [],
          };
          setDocuments((prevDocuments) => [...prevDocuments, newFolder]);
          setHistory((prevHistory) =>
            prevHistory.map((historyEntry) => addDocumentRecursively(historyEntry, newFolder))
          );
        }
        setUploadFinished(true);
      });
    });

    // setUploadFinished(true);
  };

  const createNewFolder = (folderName: string) => {
    const parentId = breadcrumbs?.[breadcrumbs.length - 1]?.id ?? null;
    const folder: CreateDocumentDto = { organizationId: orgId, filename: folderName, parentId, isFolder: true };
    uploadFileFolder({
      createDocumentDto: folder,
    }).then((response) => {
      if ('data' in response) {
        const newFolder: DocumentDto = {
          name: folder.filename,
          size: folder.fileSize,
          creationDate: new Date().toISOString(),
          parentId: parentId,
          isFolder: true,
          id: response.data.id,
          children: [],
        };
        setDocuments((prevDocuments) => [...prevDocuments, newFolder]);
        setHistory((prevHistory) => prevHistory.map((historyEntry) => addDocumentRecursively(historyEntry, newFolder)));
      }
    });
  };

  const addDocumentRecursively = (docs: DocumentDto[], newDocument: DocumentDto) => {
    return docs.map((doc) => {
      let updatedChildren: DocumentDto[] = [];
      if (doc.id === newDocument.parentId) {
        // Ensure doc.children is an array before spreading
        updatedChildren = Array.isArray(doc.children) ? [...doc.children, newDocument] : [newDocument];
        // Return a new object with the updated children property
        return { ...doc, children: updatedChildren };
      } else if (doc.children && Array.isArray(doc.children)) {
        // Recursively update children
        updatedChildren = addDocumentRecursively(doc.children, newDocument);
        // Return a new object with the updated children property
        return { ...doc, children: updatedChildren };
      }
      return doc;
    });
  };

  const handleDelete = (document: DocumentDto) => {
    if (document.isFolder && document.children && document.children.length > 0) {
      setDeleteModalText(
        `Weet je zeker dat je de map ${document.name} wilt verwijderen? Deze map bevat ${document.children.length} bestanden en mappen, en deze zullen ook verwijderd worden!`
      );
    } else {
      setDeleteModalText(`Weet je zeker dat je ${document.name} wilt verwijderen?`);
    }
    setDeletedDocument(document);
    setDeleteModalOpened(true);
  };
  const processDelete = () => {
    deleteFileFolder({ id: deletedDocument?.id ?? '' }).then(() => {
      // Recursive function to remove a document by id from a document and its children
      const removeDocumentRecursively = (docs: DocumentDto[], idToRemove: string) => {
        return docs
          .filter((doc) => doc.id !== idToRemove) // Remove the document if its id matches
          .map((doc) => {
            let document = doc;
            if (doc.children) {
              // If the document has children, apply the function recursively
              document = { ...doc, children: [...removeDocumentRecursively(doc.children, idToRemove)] };
            }
            return document;
          });
      };

      // Update documents state, applying the recursive removal
      setDocuments((prevDocuments) => removeDocumentRecursively(prevDocuments, deletedDocument?.id ?? ''));

      // Update history state, applying the recursive removal to each history entry
      setHistory((prevHistory) =>
        prevHistory.map((historyEntry) => removeDocumentRecursively(historyEntry, deletedDocument?.id ?? ''))
      );
    });
  };
  const cleanupDelete = () => {
    setDeleteModalText('');
    setDeletedDocument(undefined);
    setDeleteModalOpened(false);
  };
  const handleDownload = (document: DocumentDto) => {
    triggerDownload({ fileId: document?.id ?? 'unknown' });
  };
  const fileResult = downloadResult.data;
  useEffect(() => {
    async function downloadFile() {
      if (!fileResult?.fileName || !fileResult?.fileContents) return;

      const blob = await base64ToBlob(fileResult?.fileContents, extensionToMimeType(fileResult.fileName));
      saveAs(blob, `${fileResult?.fileName}`);
    }
    downloadFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadResult]);

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <div>
        <div className='flex items-center py-3 bg-orange-100'>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
        <div className='px-2 -mt-11 float-end'>
          {userInRole(user, 'admin') && (
            <OrganizationDocumentDropdown
              onNewFolder={(folderName) => createNewFolder(folderName)}
              onNewFile={(uploadedFiles) => uploadFiles(uploadedFiles)}
              processingErrors={uploadingError}
              isUploading={isUploading}
              uploadFinished={uploadFinished}
            />
          )}
        </div>
      </div>
      <table className='min-w-full divide-y divide-gray-200'>
        <thead className='bg-gray-50'>
          <TableHeadings onSort={setSortFieldAndDirection} />
        </thead>
        <tbody role='rowgroup'>
          {level > 0 && (
            <tr className='bg-white cursor-pointer hover:bg-yellow-50 hover:shadow-xl' onClick={navigateBack}>
              <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap' colSpan={1}>
                ⬅️
              </td>
              <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap' colSpan={4}>
                Vorige
              </td>
            </tr>
          )}
          {sortedDocuments.length === 0 && (
            <tr className='bg-white cursor-pointer hover:bg-yellow-50 hover:shadow-xl'>
              <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap' colSpan={5}>
                Geen documenten gevonden
              </td>
            </tr>
          )}
          {sortedDocuments.map((document) => (
            <DocumentRow
              key={document.id}
              document={document}
              onNavigate={navigateToFolder}
              onDelete={(document) => handleDelete(document)}
              onDownload={(document) => handleDownload(document)}
            />
          ))}
        </tbody>
        <tfoot className='bg-gray-50'>
          <TableHeadings onSort={setSortFieldAndDirection} />
        </tfoot>
      </table>
      <Modal
        isOpened={deleteModalOpened}
        type='error'
        title={'Verwijderen'}
        description={deleteModalText}
        cancelButtonText={'Annuleren'}
        actionButtonText={'Verwijderen'}
        onAction={processDelete}
        onClose={() => cleanupDelete()}
      />
    </>
  );
};

const Breadcrumbs: React.FC<{
  breadcrumbs: DocumentDto[];
}> = ({ breadcrumbs }) => {
  return (
    <div className='flex px-4 space-x-2 text-sm leading-6 text-orange-600'>
      <strong className='font-semibold cursor-default'>Home</strong>
      {breadcrumbs.map((crumb, idx) => (
        <React.Fragment key={idx}>
          <span>&gt;</span>
          <span className='text-orange-600 cursor-default'>{crumb.name}</span>
        </React.Fragment>
      ))}
    </div>
  );
};

const TableHeadings: React.FC<{ onSort: (field: string) => void }> = ({ onSort }) => {
  return (
    <tr role='row'>
      <th scope='col' className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'></th>
      <th
        scope='col'
        className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
        onClick={() => onSort('name')}
      >
        Name
      </th>
      <th scope='col' className='px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase'>
        Size
      </th>
      <th
        scope='col'
        className='px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase'
        onClick={() => onSort('creationDate')}
      >
        Datum
      </th>
      <th scope='col' className='px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase'></th>
    </tr>
  );
};

const DocumentRow: React.FC<DocumentRowProps> = ({ document, onNavigate, onDelete, onDownload }) => {
  const isFolder = document.isFolder;
  const totalSize = isFolder ? calculateTotalSize(document) : document.size ?? 0;
  const { user } = useSelector((state: RootState) => state.auth);
  const isAdmin = userInRole(user, 'admin');
  return (
    <tr
      className={
        isFolder
          ? 'bg-white cursor-pointer hover:bg-yellow-50 hover:shadow-xl'
          : 'bg-white hover:bg-yellow-50 hover:shadow-xl'
      }
    >
      {isFolder && <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>📁</td>}
      {!isFolder && <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>📜</td>}
      <td
        className='px-6 py-4 text-sm text-gray-800 cursor-pointer whitespace-nowrap'
        onClick={() => (isFolder && onNavigate(document)) || (!isFolder && onDownload(document))}
      >
        {document.name}
      </td>
      <td
        className={`px-6 py-4 text-sm text-gray-800 whitespace-nowrap text-right cursor-pointer`}
        onClick={() => (isFolder && onNavigate(document)) || (!isFolder && onDownload(document))}
      >
        {bytesToString(totalSize)}
      </td>
      <td className='px-6 py-4 text-sm text-right text-gray-800 whitespace-nowrap'>
        {document.creationDate
          ? new Date(document.creationDate).toLocaleDateString('nl-NL') +
            ' ' +
            new Date(document.creationDate).toLocaleTimeString('nl-NL', {
              hour: '2-digit',
              minute: '2-digit',
              hourCycle: 'h23',
            })
          : ''}
      </td>
      <td className='px-6 py-4 text-sm text-right text-gray-800 whitespace-nowrap'>
        {isAdmin && (
          <Button size='xs' type='error' onClick={() => onDelete(document)}>
            <TrashIcon className='w-4 h-4' />
          </Button>
        )}
      </td>
    </tr>
  );
};
const calculateTotalSize = (document: DocumentDto): number => {
  // Base case: if the document has no children, return its size or 0 if undefined
  if (!document.children || document.children.length === 0) {
    return document.size ?? 0;
  }

  // Recursive case: sum the size of all children
  return document.children.reduce((total, child) => total + calculateTotalSize(child), 0);
};

import { combineReducers } from '@reduxjs/toolkit';
import { backendApi } from 'features/services/api';
import { reducer as authReducer } from 'redux-oidc';

const rootReducer = combineReducers({
  auth: authReducer,
  // Add the generated reducer as a specific top-level slice
  [backendApi.reducerPath]: backendApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;

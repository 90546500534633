import { ChevronLeftIcon } from '@heroicons/react/outline';
import { OrganizationTabPage } from 'features/pages/management/AdminOrganizationPage';
import { useGetOrganization } from 'features/services/api';
import { Organization } from 'features/services/apiGenerated';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router';

import { useDeleteOrganizations } from '../services/api';
import { OrganizationActionDropdown } from './OrganizationActionDropdown';
import './OrganizationsTable';

export const OrganizationHeader: React.FC<{ orgId: number; onItemAdded: (page: OrganizationTabPage) => void }> = ({
  orgId,
  onItemAdded,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data, isLoading } = useGetOrganization({ id: orgId });
  const [deleteOrganisation] = useDeleteOrganizations();

  if (isLoading) {
    return (
      <div style={{ fontSize: 25 }}>
        <Skeleton count={3} />
      </div>
    );
  }

  const handleDelete = () => {
    deleteOrganisation({ id: orgId });
    setTimeout(() => {
      navigate(`/management/organizations`, {
        replace: true,
      });
    }, 250);
  };

  return (
    <div className='bg-white shadow'>
      <div className='px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8'>
        <div className='pt-2 pb-4 lg:border-gray-200'>
          <dl className='flex flex-col mt-2 mb-4 sm:flex-row sm:flex-wrap'>
            <dd
              className='flex items-center text-sm font-medium text-gray-500 cursor-pointer sm:mr-6'
              onClick={() => navigate(-1)}
            >
              <ChevronLeftIcon className='flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400' />
              {t('Terug naar vorige pagina')}
            </dd>
          </dl>
          <div className='grid grid-cols-1 gap-5 mt-2 sm:grid-cols-2 lg:grid-cols-3'>
            {/* Profile */}
            <div>
              <div className='flex items-center'>
                <h1 className='flex items-center justify-start ml-3 space-x-2 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate'>
                  <span className='tracking-tight'>{data?.name}</span>
                  <span className='flex-grow-0 w-20 h-1 bg-black'></span>
                </h1>
              </div>

              {/* <dl className='flex flex-col mt-6 sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap'>
                {data?.isActive === true && (
                  <dd className='flex items-center mt-3 text-sm font-medium text-gray-500 capitalize sm:mr-6 sm:mt-0'>
                    <CheckCircleIcon className='flex-shrink-0 mr-1.5 h-5 w-5 text-green-400' aria-hidden='true' />
                    {t('Account actief')}
                  </dd>
                )}
                {data?.isActive === false && (
                  <dd className='flex items-center mt-3 text-sm font-medium text-gray-500 capitalize sm:mr-6 sm:mt-0'>
                    <XCircleIcon className='flex-shrink-0 mr-1.5 h-5 w-5 text-red-400' aria-hidden='true' />
                    {t('Account inactief')}
                  </dd>
                )}
              </dl> */}
              <dl className='flex flex-col mt-3 ml-3 sm:flex-row sm:flex-wrap'>
                <dd
                  className='flex items-center text-sm font-medium text-gray-500 cursor-pointer sm:mr-6'
                  onClick={() => navigate(-1)}
                >
                  <ChevronLeftIcon className='flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400' />
                  {t('Terug naar alle organisaties')}
                </dd>
              </dl>
            </div>
            {data?.logoUri ? (
              <img className='object-contain w-full h-16' src={data?.logoUri ?? ''} alt='' />
            ) : (
              <div className='w-full h-16'></div>
            )}
            <div className='flex items-center justify-end'>
              <OrganizationActionDropdown
                organization={data as Organization}
                onDelete={() => handleDelete()}
                onItemAdded={onItemAdded}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

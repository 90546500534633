/* This example requires Tailwind CSS v2.0+ */
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, ExclamationIcon, TagIcon } from '@heroicons/react/solid';
import { Modal } from 'features/generic/Modal';
import { EmployeeUpsertPanel } from 'features/panels/EmployeeUpsertPanel';
import { useUpdateEmployee } from 'features/services/api';
import { CreateEmployeeDto } from 'features/services/apiGenerated';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { twClassNames } from '../../utils/twClassNames';
import { useGetEmployeeByEmployeeId } from '../services/api';

export const EmployeesActionDropdown: React.FC<{ onDelete: () => void; employeeId: number }> = ({
  onDelete,
  employeeId,
}) => {
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const [editPanelOpened, setEditPanelOpened] = useState(false);
  const { t } = useTranslation();

  const [
    updateEmployee, // This is the mutation trigger
    { isLoading, error },
  ] = useUpdateEmployee();

  const { data: employee } = useGetEmployeeByEmployeeId({ id: employeeId });
  const handleEditEmployee = (dto: CreateEmployeeDto) => {
    updateEmployee({
      id: employeeId,
      createEmployeeDto: dto,
    })
      .unwrap()
      .then(() => {
        setEditPanelOpened(false);
      });
  };
  const handleDeleteEmployee = () => {
    setDeleteModalOpened(false);
    onDelete();
  };

  return (
    <>
      <Menu as='div' className='relative inline-block text-left'>
        {({ open }) => (
          <>
            <div>
              <Menu.Button className='inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-yellow-600 border border-yellow-300 rounded-md shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-yellow-500'>
                Acties
                <ChevronDownIcon className='w-5 h-5 ml-2 -mr-1' aria-hidden='true' />
              </Menu.Button>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items
                static
                className='absolute right-0 z-50 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
              >
                <div className='py-1'>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => setEditPanelOpened(true)}
                        className={twClassNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'group flex items-center px-4 py-2 text-sm w-full'
                        )}
                      >
                        <TagIcon className='w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500' aria-hidden='true' />
                        {t('Medewerker wijzigen')}
                      </button>
                    )}
                  </Menu.Item>
                </div>
                <div className='py-1'>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => setDeleteModalOpened(true)}
                        className={twClassNames(
                          active ? 'bg-gray-100 text-red-900' : 'text-red-700',
                          'group flex items-center px-4 py-2 text-sm w-full'
                        )}
                      >
                        <ExclamationIcon
                          className='w-5 h-5 mr-3 text-red-500 group-hover:text-red-600'
                          aria-hidden='true'
                        />
                        {t('Gebruiker verwijderen')}
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      <Modal
        isOpened={deleteModalOpened}
        onClose={() => setDeleteModalOpened(false)}
        type='error'
        title={t('Medewerker verwijderen')}
        description={t('Weet je zeker dat je deze medewerker wilt verwijderen?')}
        cancelButtonText={t('Annuleren')}
        actionButtonText={t('Verwijderen')}
        onAction={handleDeleteEmployee}
      />
      <EmployeeUpsertPanel
        title={t('Medewerker wijzigen')}
        description={t('Wijzig een bestaand gebruiker met onderstaand formulier.')}
        isOpened={editPanelOpened}
        onClose={() => {
          setEditPanelOpened(false);
        }}
        orgId={employee?.organizationId as number}
        onSubmit={(employee: CreateEmployeeDto) => {
          handleEditEmployee(employee);
        }}
        isLoading={isLoading}
        error={error}
        employee={employee}
      />
    </>
  );
};

import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useGetMyOrganization } from 'features/services/api';
import { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RootState } from '../../../app/rootReducer';
import logo from '../../../assets/images/logo/tra_logo_wit.png';
import { hasAdminAccess } from '../../auth/Roles';
import { MenuItems } from '../../layout/MenuItems';
// import { TopNavLanguageChooser } from '../../layout/TopNavLanguageChooser';
// import { TopNavNotificationButton } from '../../layout/TopNavNotificationButton';
// import { TopNavProfileMenu } from '../../layout/TopNavProfileMenu';
import { PropsChildrenOnly } from '../../types';

export const MainLayout: React.FC = ({ children }: PropsChildrenOnly) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarDesktopClosed, setSidebarDesktopClosed] = useState(false);
  const location = useLocation();
  const { user } = useSelector((state: RootState) => state.auth);
  const { data: organizationInfo } = useGetMyOrganization();

  useEffect(() => {
    setSidebarOpen(false);
  }, [location]);
  return (
    <div className='flex h-screen overflow-hidden bg-gray-100'>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as='div'
          static
          className='fixed inset-0 z-40 flex md:hidden'
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-600 bg-opacity-75' />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'
          >
            <div className='relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-gray-800'>
              <Transition.Child
                as={Fragment}
                enter='ease-in-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in-out duration-300'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='absolute top-0 right-0 pt-2 -mr-12'>
                  <button
                    className='flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className='sr-only'>Close sidebar</span>
                    <XIcon className='w-6 h-6 text-white' aria-hidden='true' />
                  </button>
                </div>
              </Transition.Child>
              <div className='flex items-center flex-shrink-0 px-4'>
                <img className='w-auto h-12' src={logo} alt='Workflow' />
              </div>
              <div className='flex-1 h-0 mt-5 overflow-y-auto'>
                <MenuItems menuType='mobile' organizationInfo={organizationInfo} />
              </div>
              {!hasAdminAccess(user) && organizationInfo && (
                <div className='flex items-center px-2 py-4 bg-gray-800 justify-left'>
                  {organizationInfo.logoUri &&
                    (organizationInfo.brandingType === 'LogoAndName' ||
                      organizationInfo.brandingType === 'LogoOnly') && (
                      <img src={organizationInfo.logoUri} className='w-12 h-12' />
                    )}
                  {(organizationInfo.brandingType === 'LogoAndName' ||
                    organizationInfo.brandingType === 'NameOnly') && (
                    <p className='pl-2 text-lg font-semibold tracking-wider text-gray-100'>{organizationInfo?.name}</p>
                  )}
                </div>
              )}
            </div>
          </Transition.Child>
          <div className='flex-shrink-0 w-14' aria-hidden='true'>
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      <Transition
        enter='transition ease-in-out duration-50 transform'
        enterFrom='-translate-x-full'
        enterTo='translate-x-0'
        leave='transition ease-in-out duration-50 transform'
        leaveFrom='translate-x-0'
        leaveTo='-translate-x-full'
        show={!sidebarDesktopClosed}
        as={Fragment}
      >
        <div className='hidden md:flex md:flex-shrink-0'>
          <div className='flex flex-col w-64'>
            <div className='flex flex-col flex-1 h-0'>
              <div className='flex items-center px-4 py-2 bg-gray-800'>
                <img className='w-auto h-12' src={logo} alt='Workflow' />
              </div>
              <MenuItems menuType='desktop' organizationInfo={organizationInfo} />
            </div>
            {!hasAdminAccess(user) && organizationInfo && (
              <div className='flex flex-col items-center px-2 py-4 bg-gray-800 justify-left'>
                {organizationInfo.logoUri &&
                  (organizationInfo.brandingType === 'LogoAndName' || organizationInfo.brandingType === 'LogoOnly') && (
                    <img src={organizationInfo.logoUri} className='object-contain w-full h-28' />
                  )}
                {(organizationInfo.brandingType === 'LogoAndName' || organizationInfo.brandingType === 'NameOnly') && (
                  <p className='pl-2 text-lg font-semibold tracking-wider text-gray-100'>{organizationInfo?.name}</p>
                )}
              </div>
            )}
          </div>
        </div>
      </Transition>

      <div className='flex flex-col flex-1 w-0 overflow-hidden'>
        <main
          className={`relative z-0 flex-1  focus:outline-none ${
            !location.pathname.includes('/app/reports/') ? 'overflow-y-auto' : ''
          }`}
        >
          {children}
        </main>
      </div>
    </div>
  );
};

/* eslint-disable react/jsx-key */

/* eslint-disable @typescript-eslint/ban-types */
import {
  CheckCircleIcon,
  ChevronDoubleUpIcon,
  ChevronRightIcon,
  CloudDownloadIcon,
  XCircleIcon,
} from '@heroicons/react/outline';
import { ChevronDoubleDownIcon } from '@heroicons/react/solid';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import classNames from 'classnames';
import { CSVLink } from 'react-csv';
import Skeleton from 'react-loading-skeleton';
import {
  Column,
  useTable,
  useGlobalFilter,
  useSortBy,
  UseFiltersColumnOptions,
  UseGroupByColumnOptions,
  UseSortByColumnOptions,
} from 'react-table';

import { TableGlobalFilter } from './TableGlobalFilter';

type TableProps<T extends object> = {
  data: T[];
  error?: FetchBaseQueryError | SerializedError | undefined;
  columns: Column<T>[];
  onRowClick?: (row: T) => void;
  isLoading: boolean;
};

export interface ColumnInterface<D extends object = {}>
  extends UseFiltersColumnOptions<D>,
    UseGroupByColumnOptions<D>,
    UseSortByColumnOptions<D> {
  className?: string;
}

export function Table<T extends object>({ columns, data, onRowClick, isLoading, error }: TableProps<T>) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter } = useTable<T>(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );

  const { globalFilter } = state;

  const handleRowClick = (row: T) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const exportableData = data.map((obj) => Object.entries(obj).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {}));

  // Render the UI for your table
  return (
    <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
      <div className='block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
        <div className='border-b-4 border-tra-yellow sm:rounded-lg'>
          <div className='flex flex-col space-y-2'>
            <div className='flex flex-row items-center space-x-4'>
              <div className='flex-grow'>
                <TableGlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
              </div>
              <div>
                <CSVLink
                  data={exportableData}
                  filename={'TRA-portal-export.csv'}
                  className='items-center justify-center flex-auto px-4 py-2 ml-4 text-sm font-medium text-white bg-yellow-600 border border-transparent rounded-md shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                  target='_blank'
                >
                  Export
                </CSVLink>
              </div>
            </div>
            <table className='min-w-full divide-y divide-gray-200' {...getTableProps()}>
              <thead className='bg-gray-50'>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      const cellClassName = classNames(
                        'px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                      );
                      return (
                        <th
                          scope='col'
                          className={cellClassName}
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                        >
                          <span className='inline'>
                            <span className={`block ${column.className}`}>{column.render('Header')}</span>
                          </span>
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <ChevronDoubleDownIcon className='inline w-3 h-3 ml-1 text-yellow-700' />
                              ) : (
                                <ChevronDoubleUpIcon className='inline w-3 h-3 ml-1 text-yellow-700' />
                              )
                            ) : (
                              ''
                            )}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              {isLoading && (
                <tbody>
                  <tr>
                    <td colSpan={headerGroups[0].headers.length}>
                      <div style={{ fontSize: 25 }}>
                        <Skeleton count={10} />
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
              {error && (
                <tbody>
                  <tr>
                    <td colSpan={headerGroups[0].headers.length}>
                      <div className='p-4 rounded-md bg-red-50'>
                        <div className='flex'>
                          <div className='flex-shrink-0'>
                            <XCircleIcon className='w-5 h-5 text-red-400' aria-hidden='true' />
                          </div>
                          <div className='ml-3'>
                            <h3 className='text-sm font-medium text-red-800'>Er is iets fout gegaan:</h3>
                            <div className='mt-2 text-sm text-red-700'>
                              <ul className='pl-5 space-y-1 list-disc'>{JSON.stringify(error)}</ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
              {!isLoading && !error && (
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    const rowClassNames = classNames({
                      'bg-white': i % 2 === 0,
                      'bg-gray-50': i % 2 !== 0,
                      'cursor-pointer hover:bg-yellow-50 hover:shadow-xl': onRowClick !== undefined,
                    });
                    prepareRow(row);
                    return (
                      <tr className={rowClassNames} onClick={() => handleRowClick(row.original)} {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          const cellClassName = classNames('px-6 py-4 text-sm text-gray-800 whitespace-nowrap');
                          return (
                            <td className={cellClassName} {...cell.getCellProps()}>
                              <span className={`block ${cell.column.className}`}>{cell.render('Cell')}</span>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              )}

              <tfoot className='bg-gray-50'>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        scope='col'
                        className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                        {...column.getHeaderProps()}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
export const TableCellChevronRight = <ChevronRightIcon className='w-5 h-5 text-gray-600' />;
export const TableCellDownload = <CloudDownloadIcon className='w-5 h-5 text-gray-600' />;
export const TableCellActiveIcon = (
  <p className='flex items-center justify-center'>
    <CheckCircleIcon className='w-5 h-5 text-green-400' />
  </p>
);
export const GridActiveIcon = <CheckCircleIcon className='w-5 h-5 text-green-400' />;
export const TableCellInactiveIcon = (
  <p className='flex items-center justify-center'>
    <XCircleIcon className='w-5 h-5 text-red-400' />
  </p>
);
export const GridInactiveIcon = <XCircleIcon className='w-5 h-5 text-red-400' />;

import { UserCircleIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

import { EmployeesTable } from '../../employees/EmployeesTable';
import { useGetEmployee } from '../../services/api';

export const AdminEmployeeOverviewPage = () => {
  const { t } = useTranslation();
  const { data, isLoading, error } = useGetEmployee();
  return (
    <div>
      <div className='py-6 space-y-4'>
        <div className='flex flex-row justify-between px-4 mx-auto space-y-4 max-w-7xl sm:px-6 lg:px-8'>
          {/* <Breadcrumbs /> */}
          <h1 className='flex items-center justify-start space-x-2 text-3xl font-bold text-gray-900 uppercase'>
            <UserCircleIcon className='w-8 h-8' />
            <span className='tracking-tight'>{t('Medewerkers')}</span>
            <span className='flex-grow-0 w-20 h-1 bg-black'></span>
          </h1>
        </div>

        <div className='px-4 mx-auto space-y-4 max-w-7xl sm:px-6 md:px-8'>
          <EmployeesTable data={data} isLoading={isLoading} error={error} />
        </div>
      </div>
    </div>
  );
};

import { FaqEntry } from './FaqEntry';

/* This example requires Tailwind CSS v2.0+ */
const faqs = [
  {
    question: 'Ik krijg een foutmelding bij het uploaden van mijn data',
    answer: 'Controleer of de data in het afgesproken formaat (CSV, xlsx of txt.) is opgeleverd.',
  },
  {
    question: 'Ik heb de verkeerde data geüpload, wat moet ik doen? ',
    answer:
      'Als dit, volgens SLA, nog voor het afgesproken moment van verwerking is dan kun je de foutief geüploade data zelf verwijderen, neem anders telefonisch contact op en we lossen het voor je op!',
  },
  {
    question: 'Ik wil graag een collega machtigen binnen het portaal om rapportages te bekijken, hoe doe ik dit?',
    answer:
      'Stuur de gegevens van de desbetreffende collega (naam, e-mailadres en telefoonnummer) naar info@thereportingagency.nl, hij of zij ontvangt vanuit ons de instructies en gegevens om een account aan te maken. ',
  },
  {
    question: 'Ik heb een andere vraag!',
    answer:
      'Uiteraard staan we klaar om uw vragen te beantwoorden, gebruik onderstaand contactformulier om contact met ons op te nemen!',
  },

  // More questions...
];
export const FaqOverview = () => {
  return (
    <div className='mt-12 lg:mt-0 lg:col-span-2'>
      <dl className='space-y-12'>
        {faqs.map((faq) => (
          <FaqEntry key={faq.question} {...faq} />
        ))}
      </dl>
    </div>
  );
};

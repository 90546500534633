/* This example requires Tailwind CSS v2.0+ */
import { Menu, Transition } from '@headlessui/react';
import { FolderAddIcon } from '@heroicons/react/outline';
import { ChevronDownIcon, UserAddIcon } from '@heroicons/react/solid';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { DocumentNewFolderPanel } from 'features/panels/DocumentNewFolderPanel';
import { DocumentFileUploadPanel } from 'features/panels/DocumentUploadFilePanel';
import { CreateDocumentDto } from 'features/services/apiGenerated';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { twClassNames } from '../../utils/twClassNames';

export const OrganizationDocumentDropdown: React.FC<{
  onNewFolder: (folderName: string) => void;
  onNewFile: (uploadedFiles: CreateDocumentDto[]) => void;
  processingErrors: FetchBaseQueryError | SerializedError | undefined;
  isUploading: boolean;
  uploadFinished: boolean;
}> = ({ onNewFolder, onNewFile, processingErrors, isUploading, uploadFinished }) => {
  const [createFolderOpened, setCreateFolderOpened] = useState(false);
  const [uploadFileOpened, setUploadFileOpened] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (uploadFinished) {
      setUploadFileOpened(false);
    }
  }, [uploadFinished]);
  return (
    <>
      <Menu as='div' className='relative inline-block text-left'>
        {({ open }) => (
          <>
            <div>
              <Menu.Button className='inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-orange-600 border border-orange-300 rounded-md shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-orange-500'>
                Acties
                <ChevronDownIcon className='w-5 h-5 ml-2 -mr-1' aria-hidden='true' />
              </Menu.Button>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items
                static
                className='absolute right-0 z-50 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
              >
                <div className='py-1'>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => setCreateFolderOpened(true)}
                        className={twClassNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'group flex items-center px-4 py-2 text-sm w-full'
                        )}
                      >
                        <FolderAddIcon
                          className='w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500'
                          aria-hidden='true'
                        />
                        {t('Nieuwe map aanmaken')}
                      </button>
                    )}
                  </Menu.Item>
                </div>
                <div className='py-1'>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => setUploadFileOpened(true)}
                        className={twClassNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'group flex items-center px-4 py-2 text-sm w-full'
                        )}
                      >
                        <UserAddIcon
                          className='w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500'
                          aria-hidden='true'
                        />
                        {t('Bestand(en) uploaden')}
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>

      <DocumentNewFolderPanel
        title={t('Nieuwe map')}
        description={t('Vul hieronder de naam in van de nieuwe map die je wilt aanmaken')}
        isOpened={createFolderOpened}
        onClose={() => {
          setCreateFolderOpened(false);
        }}
        onSubmit={(folderName: string) => {
          onNewFolder(folderName);
          setCreateFolderOpened(false);
        }}
        isLoading={false}
      />
      <DocumentFileUploadPanel
        title={t('Bestand(en) uploaden')}
        description={t('Upload hieronder de bestanden die je wilt toevoegen')}
        isOpened={uploadFileOpened}
        isUploading={isUploading}
        onClose={() => {
          setUploadFileOpened(false);
        }}
        onSubmit={(uploadedFiles: CreateDocumentDto[]) => {
          onNewFile(uploadedFiles);
        }}
        processingError={processingErrors}
      />
      {/* <DocumentUploadFilePanel
        title={t('Organisatie wijzigen')}
        description={t('Wijzig de organisatie door hieronder de gegevens aan te passen')}
        isOpened={uploadFileOpened}
        onClose={() => {
          setUploadFileOpened(false);
        }}
        onSubmit={(dto: CreateOrganizationDto) => {
          handleCreateFile(dto);
          setUploadFileOpened(false);
        }}
        isLoading={isUpdatingOrganization}
        error={updateOrganizationError}
      /> */}
    </>
  );
};

import { InformationCircleIcon, UploadIcon, CloudUploadIcon } from '@heroicons/react/outline';
import { Card } from 'features/generic/Card';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { twClassNames } from 'utils/twClassNames';

import i18n from '../../../i18n/config';
import { UploadTaskInformation } from '../../uploadTasks/UploadTaskInformation';
import { UploadTaskUploadsTable } from '../../uploadTasks/UploadTaskUploadsTable';
import { UploadTaskHeader } from '../../uploadTasks/UploadTasksHeader';
import { OrganizationTabPage } from './AdminOrganizationPage';

type TabPage = 'general' | 'customerUploads' | 'reportingAgencyUploads';

interface Tab {
  name: string;
  type: TabPage;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
}
const tabs: Tab[] = [
  { name: i18n.t('Informatie'), type: 'general', icon: InformationCircleIcon },
  { name: i18n.t('Uploads door klanten'), type: 'customerUploads', icon: UploadIcon },
  { name: i18n.t('Uploads door Reporting Agency '), type: 'reportingAgencyUploads', icon: CloudUploadIcon },
];
export const AdminUploadTaskPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<TabPage>('general');
  const navigate = useNavigate();
  const taskId = Number.parseInt(id?.toString() ?? '');
  if (!id) {
    navigate('/management/organizations', { replace: true, state: { tab: 'uploadTasks' as OrganizationTabPage } });
    return <></>;
  }

  return (
    <main className='relative z-0 flex-1 pb-8 overflow-y-auto'>
      <UploadTaskHeader taskId={taskId} />
      <div className='mt-8'>
        <div className='max-w-6xl px-4 mx-auto mt-8 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8'>
          <Card className='px-4 py-6 bg-white sm:px-6 lg:px-8'>
            <div className='sm:hidden'>
              <label htmlFor='tabs' className='sr-only'>
                {t('Kies pagina')}
              </label>
              <select
                id='tabs'
                name='tabs'
                className='block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm'
                defaultValue={tabs.find((tab) => tab.type === activeTab)?.name}
                onChange={(e) => setActiveTab(e.target.value as TabPage)}
              >
                {tabs.map((tab) => (
                  <option key={tab.name} value={tab.type}>
                    {tab.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='hidden sm:block'>
              <div className='border-b border-gray-200'>
                <nav className='flex -mb-px space-x-8' aria-label='Tabs'>
                  {tabs.map((tab) => (
                    <button
                      key={tab.name}
                      onClick={() => setActiveTab(tab.type)}
                      className={twClassNames(
                        tab.type === activeTab
                          ? 'border-yellow-500 text-yellow-600'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                        'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm focus:outline-none'
                      )}
                      aria-current={tab.type === activeTab ? 'page' : undefined}
                    >
                      <tab.icon
                        className={twClassNames(
                          tab.type === activeTab ? 'text-yellow-500' : 'text-gray-400 group-hover:text-gray-500',
                          '-ml-0.5 mr-2 h-5 w-5'
                        )}
                        aria-hidden='true'
                      />
                      <span>{tab.name}</span>
                    </button>
                  ))}
                </nav>
              </div>
            </div>
            <div className='mt-4'>
              {activeTab === 'general' && <UploadTaskInformation uploadTaskId={taskId} />}
              {activeTab === 'customerUploads' && <UploadTaskUploadsTable uploadTaskId={taskId} view='customer' />}
              {activeTab === 'reportingAgencyUploads' && (
                <UploadTaskUploadsTable uploadTaskId={taskId} view='management' />
              )}
            </div>
          </Card>
        </div>
      </div>
    </main>
  );
};

import { models, service } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useState, useEffect } from 'react';

import { useGetPowerBIEmbedParams } from '../services/api';

export const ReportPowerBIPreview: React.FC<{ reportId: string }> = ({ reportId }) => {
  const { data, isLoading } = useGetPowerBIEmbedParams({ id: reportId });
  const [reportConfig, setReportConfig] = useState<models.IReportEmbedConfiguration>({
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: undefined,
  });
  useEffect(() => {
    if (data && data.embedUrl && reportConfig.accessToken === undefined) {
      // Update display message
      const embedUrl = data.embedUrl;
      const accessToken = data?.embedToken?.token ?? '';
      // Set the fetched embedUrl and embedToken in the report config
      if (accessToken && embedUrl) {
        setReportConfig({
          ...reportConfig,
          embedUrl: embedUrl,
          accessToken: accessToken,
          settings: {
            panes: {
              filters: {
                visible: false,
              },
            },
            localeSettings: {
              language: 'nl',
              formatLocale: 'nl',
            },
            background: models.BackgroundType.Transparent,
            hideErrors: true,
          },
        });
      }
    }
  }, [data, reportConfig]);
  const eventHandlersMap = new Map([
    [
      'loaded',
      function () {
        console.log('Report has loaded');
      },
    ],
    [
      'rendered',
      function () {
        console.log('Report has rendered');

        // Update display message
      },
    ],
    [
      'error',
      function (event?: service.ICustomEvent<unknown>) {
        if (event) {
          console.error(event.detail);
        }
      },
    ],
  ]);

  // if (error) {
  //   return <>Error: {JSON.stringify(error)}</>;
  // }
  if (!isLoading && data) {
    return <PowerBIEmbed embedConfig={reportConfig} eventHandlers={eventHandlersMap} cssClassName={'w-full h-full'} />;
  }

  return <></>;
};

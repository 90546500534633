import { AdminUserHeader } from 'features/adminusers/AdminUserHeader';
import { Card } from 'features/generic/Card';
import { useParams, useNavigate } from 'react-router-dom';

export const AdminUserPage = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const userId = id?.toString();

  if (!id) {
    navigate('/management/adminusers', { replace: true });
    return <></>;
  }

  return (
    <main className='relative z-0 flex-1 pb-8 overflow-y-auto'>
      <AdminUserHeader userId={userId} />
      <div className='mt-8'>
        <div className='max-w-6xl px-4 mx-auto mt-8 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8'>
          <Card className='px-4 py-6 bg-white sm:px-6 lg:px-8'>
            <div className='hidden sm:block'>
              <div className='border-b border-gray-200'>
                <p>Hierboven kun je deze beheerder met de actieknop verwijderen.</p>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </main>
  );
};

/* eslint-disable react/jsx-key */
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Column } from 'react-table';
import { stringDateToLocaleString } from 'utils/dateUtils';

import { Table, TableCellActiveIcon, TableCellChevronRight, TableCellInactiveIcon } from '../generic/Table';
import { ReportInfoDto } from '../services/apiGenerated';

export const ReportsTableSimple: React.FC<{
  data: ReportInfoDto[] | undefined;
  isLoading: boolean;
  error?: FetchBaseQueryError | SerializedError;
}> = ({ data, isLoading, error }) => {
  const { t } = useTranslation();

  const onRowClick = (row: ReportInfoDto) => {
    navigate(`/management/reports/${row.id}`);
  };

  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/ban-types
  const columns: Column<ReportInfoDto>[] = [
    {
      Header: t('Rapportage').toString(),
      accessor: 'name',
    },
    {
      Header: t('Type').toString(),
      accessor: 'type',
    },
    {
      Header: t('Laatste wijziging').toString(),
      accessor: (row) => {
        if (!row.lastUpdatedDate) {
          return '';
        }
        return stringDateToLocaleString(row.lastUpdatedDate);
      },
      sortType: (rowA, rowB) => {
        if (rowA.original.lastUpdatedDate === undefined || rowA.original.lastUpdatedDate === null) return 0;
        if (rowB.original.lastUpdatedDate === undefined || rowB.original.lastUpdatedDate === null) return 0;
        if (new Date(rowA.original.lastUpdatedDate) > new Date(rowB.original.lastUpdatedDate)) return 1;
        if (new Date(rowB.original.lastUpdatedDate) > new Date(rowA.original.lastUpdatedDate)) return -1;
        return 0;
      },
    },
    {
      Header: t('Aangemaakt').toString(),
      accessor: (row) => {
        if (!row.creationDate) {
          return '';
        }
        return stringDateToLocaleString(row.creationDate);
      },
      sortType: (rowA, rowB) => {
        if (rowA.original.creationDate === undefined || rowA.original.creationDate === null) return 0;
        if (rowB.original.creationDate === undefined || rowB.original.creationDate === null) return 0;
        if (new Date(rowA.original.creationDate) > new Date(rowB.original.creationDate)) return 1;
        if (new Date(rowB.original.creationDate) > new Date(rowA.original.creationDate)) return -1;
        return 0;
      },
    },
    {
      Header: t('Actief').toString(),
      accessor: (row) => (row.isActive ? TableCellActiveIcon : TableCellInactiveIcon),
    },
    {
      Header: ' ',
      accessor: () => TableCellChevronRight,
    },
  ];

  return <Table columns={columns} data={data ?? []} onRowClick={onRowClick} isLoading={isLoading} error={error} />;
};

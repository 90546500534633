/* eslint-disable react/jsx-key */
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Column } from 'react-table';
import { stringDateToLocaleString } from 'utils/dateUtils';

import { Table, TableCellActiveIcon, TableCellChevronRight, TableCellInactiveIcon } from '../generic/Table';
import { useGetEmployeeByOrganization } from '../services/api';
import { EmployeeInfoDto } from '../services/apiGenerated';

export const OrganizationEmployeesTable: React.FC<{ orgId: number }> = ({ orgId }: { orgId: number }) => {
  const { t } = useTranslation();

  const { data, error, isLoading } = useGetEmployeeByOrganization({ organizationId: orgId });

  const onRowClick = (row: EmployeeInfoDto) => {
    navigate(`/management/employees/${row.id}`);
  };

  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/ban-types
  const columns: Column<EmployeeInfoDto>[] = [
    {
      Header: t('Voornaam').toString(),
      accessor: 'firstName',
    },
    {
      Header: t('Achternaam').toString(),
      accessor: 'lastName',
    },
    {
      Header: t('Afdelingen').toString(),
      accessor: 'departmentCount',
    },
    {
      Header: t('Rapportages').toString(),
      accessor: (row) => (row?.canViewReports === true ? TableCellActiveIcon : TableCellInactiveIcon),
    },
    {
      Header: t('Uploaden').toString(),
      accessor: (row) => (row?.canUploadData === true ? TableCellActiveIcon : TableCellInactiveIcon),
    },
    {
      Header: t('Documenten').toString(),
      accessor: (row) => (row?.canViewDocuments === true ? TableCellActiveIcon : TableCellInactiveIcon),
    },
    {
      Header: t('Laatst actief').toString(),
      accessor: (row) => {
        if (!row.lastLoggedIn) {
          return 'Nooit';
        }
        return stringDateToLocaleString(row.lastLoggedIn);
      },
      sortType: (rowA, rowB) => {
        if (rowA.original.lastLoggedIn === undefined || rowA.original.lastLoggedIn === null) return 0;
        if (rowB.original.lastLoggedIn === undefined || rowB.original.lastLoggedIn === null) return 0;
        if (new Date(rowA.original.lastLoggedIn) > new Date(rowB.original.lastLoggedIn)) return 1;
        if (new Date(rowB.original.lastLoggedIn) > new Date(rowA.original.lastLoggedIn)) return -1;
        return 0;
      },
    },
    {
      Header: t('Actief').toString(),
      accessor: (row) => (row.isActive ? TableCellActiveIcon : TableCellInactiveIcon),
    },
    {
      Header: ' ',
      accessor: () => TableCellChevronRight,
    },
  ];

  return <Table columns={columns} data={data ?? []} onRowClick={onRowClick} isLoading={isLoading} error={error} />;
};

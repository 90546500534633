interface FAQProps {
  question: string;
  answer: string;
}

export const FaqEntry = ({ question, answer }: FAQProps) => {
  return (
    <div>
      <dt className='text-lg font-medium leading-6 text-gray-900'>{question}</dt>
      <dd className='mt-2 text-base text-gray-500'>{answer}</dd>
    </div>
  );
};

/* eslint-disable @typescript-eslint/no-empty-function */
import { Dialog, Transition } from '@headlessui/react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { Fragment, PropsWithChildren } from 'react';

interface PanelProps {
  error?: SerializedError | FetchBaseQueryError | undefined;
  isOpened: boolean;
}

export const PanelBase: React.FC<PanelProps> = ({ isOpened, children }: PropsWithChildren<PanelProps>) => (
  <Transition.Root show={isOpened} as={Fragment}>
    <Dialog as='div' static className='fixed inset-0 z-20 overflow-hidden' open={isOpened} onClose={() => {}}>
      <div className='absolute inset-0 overflow-hidden'>
        <Transition.Child
          as={Fragment}
          enter='ease-in-out duration-500'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in-out duration-500'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Dialog.Overlay className='absolute inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
        </Transition.Child>
        <div className='fixed inset-y-0 right-0 flex max-w-full pl-16'>
          <Transition.Child
            as={Fragment}
            enter='transform transition ease-in-out duration-500 sm:duration-700'
            enterFrom='translate-x-full'
            enterTo='translate-x-0'
            leave='transform transition ease-in-out duration-500 sm:duration-700'
            leaveFrom='translate-x-0'
            leaveTo='translate-x-full'
          >
            <div className='w-screen max-w-md'>{children}</div>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
);

/* This example requires Tailwind CSS v2.0+ */
import { CalendarIcon, CloudUploadIcon, RefreshIcon } from '@heroicons/react/outline';
import { CheckCircleIcon, ChevronRightIcon, InformationCircleIcon, ExclamationIcon } from '@heroicons/react/solid';
import { Card } from 'features/generic/Card';
import { getUploadStatusTranslation } from 'i18n/enumTranslations';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { stringDateToLocaleDateString, stringDateToLocaleString } from 'utils/dateUtils';

import { UploadTaskCustomerPanel } from '../panels/UploadTaskCustomerPanel';
import { useGetUploadTasks } from '../services/api';
import { UploadTaskInfoDto } from '../services/apiGenerated';

export const DataUploadPage = () => {
  const [uploadOpened, setUploadOpened] = useState(false);
  const [activeTask, setActiveTask] = useState<UploadTaskInfoDto>({});
  const { t } = useTranslation();
  const { data: uploadTasks, isLoading, error, refetch } = useGetUploadTasks();
  if (isLoading) {
    return (
      <div style={{ fontSize: 25 }}>
        <Skeleton count={5} />
      </div>
    );
  }
  const triggerUpload = (task: UploadTaskInfoDto) => {
    setActiveTask(task);
    setUploadOpened(true);
  };
  const closePanel = () => {
    refetch();
    setUploadOpened(false);
    setTimeout(() => {
      setActiveTask({});
    }, 500);
  };
  if (error) {
    <p>Something went wrong: {JSON.stringify(error)}</p>;
  }

  return (
    <div className='py-6 space-y-4'>
      <div className='px-4 mx-auto space-y-4 max-w-7xl sm:px-6 lg:px-8'>
        {/* <Breadcrumbs /> */}
        <h1 className='flex items-center justify-start space-x-2 text-3xl font-bold text-gray-900 uppercase'>
          <CloudUploadIcon className='w-8 h-8' />
          <span className='tracking-tight'>{t('Mijn data uploads')}</span>
          <span className='flex-grow-0 w-20 h-1 bg-black'></span>
        </h1>
      </div>

      <div className='px-4 mx-auto space-y-4 max-w-7xl sm:px-6 md:px-8'>
        <Card className='border-4 border-b-yellow-500'>
          <ul className='divide-y divide-gray-200'>
            {!uploadTasks ||
              (uploadTasks.length === 0 && (
                <tr>
                  <td colSpan={5} className='p-8 text-sm text-center'>
                    {t(
                      'Er zijn momenteel geen uploadverzoeken. U ontvangt automatisch een e-mail als een nieuw verzoek geplaatst wordt.'
                    )}
                  </td>
                </tr>
              ))}
            {uploadTasks
              ?.filter((x) => x.status !== 'ProcessingSuccesful')
              .map((task) => {
                const canUpload = task.status === 'IsErrorAndReupload' || task.status === 'IsWaitingForUpload';
                const pointerClass = canUpload ? 'cursor-pointer' : 'cursor-default';
                const bgClass = canUpload ? 'hover:bg-gray-50' : 'bg-green-50/50';
                return (
                  <li key={task.id}>
                    <a
                      href='#'
                      className={`block ${bgClass} ${pointerClass}`}
                      onClick={() => {
                        if (canUpload) {
                          triggerUpload(task);
                        }
                      }}
                    >
                      <div className='flex items-center px-4 py-4 sm:px-6'>
                        <div className='flex items-center flex-1 min-w-0'>
                          <div className='flex-1 min-w-0 px-4 md:grid md:grid-cols-2 md:gap-4'>
                            <div>
                              <p className='text-sm font-medium text-yellow-600 truncate'>{task.reportName}</p>
                              <p className='flex items-center mt-2 text-sm text-gray-500'>
                                <CalendarIcon
                                  className='flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400'
                                  aria-hidden='true'
                                />
                                <span className='truncate'>
                                  {t('vóór')}&nbsp;
                                  <time dateTime={stringDateToLocaleString(task.dueDate)}>
                                    {stringDateToLocaleDateString(task.dueDate)}
                                  </time>
                                </span>
                              </p>
                            </div>
                            <div className='hidden md:block'>
                              <div>
                                <p className='text-sm text-gray-900'>
                                  Laatste wijziging: <span>{stringDateToLocaleString(task.lastModifiedDate)}</span>
                                </p>
                                {task.status === 'IsWaitingForUpload' && (
                                  <p className='flex items-center mt-2 text-sm text-gray-500'>
                                    <InformationCircleIcon
                                      className='flex-shrink-0 mr-1.5 h-5 w-5 text-blue-400'
                                      aria-hidden='true'
                                    />
                                    {getUploadStatusTranslation(task.status)}
                                  </p>
                                )}
                                {task.status === 'IsBeingProcessed' && (
                                  <p className='flex items-center mt-2 text-sm text-gray-500'>
                                    <RefreshIcon
                                      className='flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400'
                                      aria-hidden='true'
                                    />
                                    {getUploadStatusTranslation(task.status)}
                                  </p>
                                )}
                                {task.status === 'IsErrorAndReupload' && (
                                  <p className='flex items-center mt-2 text-sm text-gray-500'>
                                    <ExclamationIcon
                                      className='flex-shrink-0 mr-1.5 h-5 w-5 text-red-400'
                                      aria-hidden='true'
                                    />
                                    {getUploadStatusTranslation(task.status)}
                                  </p>
                                )}
                                {task.status === 'ProcessingSuccesful' && (
                                  <p className='flex items-center mt-2 text-sm text-gray-500'>
                                    <CheckCircleIcon
                                      className='flex-shrink-0 mr-1.5 h-5 w-5 text-green-400'
                                      aria-hidden='true'
                                    />
                                    {getUploadStatusTranslation(task.status)}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          {canUpload ? (
                            <ChevronRightIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
                          ) : (
                            <CheckCircleIcon className='w-5 h-5 text-green-600' aria-hidden='true' />
                          )}
                        </div>
                      </div>
                    </a>
                  </li>
                );
              })}
            {uploadTasks
              ?.filter((x) => x.status === 'ProcessingSuccesful')
              .map((task) => {
                const canUpload = false;
                const pointerClass = canUpload ? 'cursor-pointer' : 'cursor-default';
                const bgClass = canUpload ? 'hover:bg-gray-50' : 'bg-green-50/50';
                return (
                  <li key={task.id}>
                    <a href='#' className={`block ${bgClass} ${pointerClass}`}>
                      <div className='flex items-center px-4 py-4 sm:px-6'>
                        <div className='flex items-center flex-1 min-w-0'>
                          <div className='flex-1 min-w-0 px-4 md:grid md:grid-cols-2 md:gap-4'>
                            <div>
                              <p className='text-sm font-medium text-yellow-600 truncate'>{task.reportName}</p>
                              <p className='flex items-center mt-2 text-sm text-gray-500'>
                                <CalendarIcon
                                  className='flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400'
                                  aria-hidden='true'
                                />
                                <span className='truncate'>
                                  {t('vóór')}&nbsp;
                                  <time dateTime={stringDateToLocaleString(task.dueDate)}>
                                    {stringDateToLocaleDateString(task.dueDate)}
                                  </time>
                                </span>
                              </p>
                            </div>
                            <div className='hidden md:block'>
                              <div>
                                <p className='text-sm text-gray-900'>
                                  Laatste wijziging: <span>{stringDateToLocaleString(task.lastModifiedDate)}</span>
                                </p>
                                {task.status === 'IsWaitingForUpload' && (
                                  <p className='flex items-center mt-2 text-sm text-gray-500'>
                                    <InformationCircleIcon
                                      className='flex-shrink-0 mr-1.5 h-5 w-5 text-blue-400'
                                      aria-hidden='true'
                                    />
                                    {getUploadStatusTranslation(task.status)}
                                  </p>
                                )}
                                {task.status === 'IsBeingProcessed' && (
                                  <p className='flex items-center mt-2 text-sm text-gray-500'>
                                    <RefreshIcon
                                      className='flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400'
                                      aria-hidden='true'
                                    />
                                    {getUploadStatusTranslation(task.status)}
                                  </p>
                                )}
                                {task.status === 'IsErrorAndReupload' && (
                                  <p className='flex items-center mt-2 text-sm text-gray-500'>
                                    <ExclamationIcon
                                      className='flex-shrink-0 mr-1.5 h-5 w-5 text-red-400'
                                      aria-hidden='true'
                                    />
                                    {getUploadStatusTranslation(task.status)}
                                  </p>
                                )}
                                {task.status === 'ProcessingSuccesful' && (
                                  <p className='flex items-center mt-2 text-sm text-gray-500'>
                                    <CheckCircleIcon
                                      className='flex-shrink-0 mr-1.5 h-5 w-5 text-green-400'
                                      aria-hidden='true'
                                    />
                                    {getUploadStatusTranslation(task.status)}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          {canUpload ? (
                            <ChevronRightIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
                          ) : (
                            <CheckCircleIcon className='w-5 h-5 text-green-600' aria-hidden='true' />
                          )}
                        </div>
                      </div>
                    </a>
                  </li>
                );
              })}
          </ul>
        </Card>
      </div>
      <UploadTaskCustomerPanel onClose={() => closePanel()} uploadTask={activeTask} isOpened={uploadOpened} />
    </div>
  );
};

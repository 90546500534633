import { CheckCircleIcon, ChevronLeftIcon, OfficeBuildingIcon, XCircleIcon } from '@heroicons/react/outline';
import { UploadIcon } from '@heroicons/react/outline';
import { OrganizationTabPage } from 'features/pages/management/AdminOrganizationPage';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router';

import { useGetUploadTaskById, useDeleteUploadTask } from '../services/api';
import { UploadTaskActionDropdown } from './UploadTaskActionDropdown';

export const UploadTaskHeader: React.FC<{ taskId: number }> = ({ taskId }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data, isLoading } = useGetUploadTaskById({ id: taskId });

  const [deleteUploadTask] = useDeleteUploadTask();

  const organizationId = data?.organizationId;

  if (isLoading) {
    return (
      <div style={{ fontSize: 25 }}>
        <Skeleton count={3} />
      </div>
    );
  }
  const handleDelete = () => {
    deleteUploadTask({ id: taskId });
    setTimeout(() => {
      if (organizationId) {
        navigate(`/management/organizations/${organizationId}`, {
          replace: true,
          state: { tab: 'uploadTasks' as OrganizationTabPage },
        });
      } else {
        navigate(-1);
      }
    }, 250);
  };

  return (
    <div className='bg-white shadow'>
      <div className='px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8'>
        <div className='pt-2 pb-4 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200'>
          <div className='flex-1 min-w-0'>
            <dl className='flex flex-col mt-2 mb-4 sm:flex-row sm:flex-wrap'>
              <dd
                className='flex items-center text-sm font-medium text-gray-500 cursor-pointer sm:mr-6'
                onClick={() => navigate(-1)}
              >
                <ChevronLeftIcon className='flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400' />
                {t('Terug naar vorige pagina')}
              </dd>
            </dl>
            <div className='flex items-center'>
              <UploadIcon className='w-16 h-16 text-yellow-600' />

              <div>
                <div className='flex items-center'>
                  <h1 className='ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate'>
                    {t('Aanlevering')}: {data?.reportName}
                  </h1>
                </div>
                <dl className='flex flex-col mt-6 sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap'>
                  <dt className='sr-only'>Company</dt>
                  <dd className='flex items-center text-sm font-medium text-gray-500 sm:mr-6'>
                    <OfficeBuildingIcon className='flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400' aria-hidden='true' />
                    {data?.organization?.name}
                  </dd>
                  <dt className='sr-only'>Upload status</dt>
                  {data?.isActive && (
                    <dd className='flex items-center mt-3 text-sm font-medium text-gray-500 sm:mr-6 sm:mt-0'>
                      <CheckCircleIcon className='flex-shrink-0 mr-1.5 h-5 w-5 text-green-400' aria-hidden='true' />
                      {t('Verzoek actief')}
                    </dd>
                  )}
                  {!data?.isActive && (
                    <dd className='flex items-center mt-3 text-sm font-medium text-gray-500 sm:mr-6 sm:mt-0'>
                      <XCircleIcon className='flex-shrink-0 mr-1.5 h-5 w-5 text-red-400' aria-hidden='true' />
                      {t('Verzoek niet actief')}
                    </dd>
                  )}
                </dl>
              </div>
            </div>
          </div>
          <div className='flex mt-6 space-x-3 md:mt-0 md:ml-4'>
            <UploadTaskActionDropdown onDelete={() => handleDelete()} uploadTaskId={taskId} />
          </div>
        </div>
      </div>
    </div>
  );
};

import { ChartSquareBarIcon, ExclamationIcon } from '@heroicons/react/outline';
import { useGetMyOrganization, useGetUploadTasks } from 'features/services/api';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { stringDateToLocaleString } from 'utils/dateUtils';

import { RootState } from '../../app/rootReducer';
import { hasReportViewAccess, hasUploadAccess, hasAdminAccess } from '../auth/Roles';
import Banner from '../generic/Banner';
import { Card } from '../generic/Card';
import { ReportOverviewCard } from '../reports/ReportOverviewCard';

export const Dashboard = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth);
  const { data: meInfo } = useGetMyOrganization();
  const { data: uploadTasks } = useGetUploadTasks();
  let uploadOverDue = false;
  uploadTasks?.map((ut) => {
    if (
      ut.status &&
      ut.dueDate &&
      ['IsWaitingForUpload', 'IsErrorAndReupload'].includes(ut.status) &&
      new Date(ut.dueDate) < new Date()
    ) {
      uploadOverDue = true;
    }
  });

  return (
    <div>
      <Banner />
      <div className='py-6 space-y-8'>
        {!hasAdminAccess(user) && (
          <div className='px-4 mx-auto max-w-7xl sm:px-6 lg:px-8'>
            {/* <Breadcrumbs /> */}
            <h1 className='flex items-center justify-start space-x-2 text-3xl font-bold text-gray-900 uppercase'>
              <ChartSquareBarIcon className='w-8 h-8' />
              <span className='tracking-tight'>
                {t('Welkom terug')}, {user?.profile.name}
              </span>
              <span className='flex-grow-0 w-20 h-1 bg-black'></span>
            </h1>
            {stringDateToLocaleString(meInfo?.userLastActive) && (
              <p className='text-sm italic'>Laatst actief op: {stringDateToLocaleString(meInfo?.userLastActive)}</p>
            )}
          </div>
        )}

        <div className='w-full border-t border-gray-300' />
        {hasReportViewAccess(user) && (
          <>
            <div className='px-4 mx-auto space-y-4 max-w-7xl sm:px-6 lg:px-8'>
              {/* <Breadcrumbs /> */}
              <h1 className='flex items-center justify-start space-x-2 text-3xl font-bold text-gray-900 uppercase'>
                <ChartSquareBarIcon className='w-8 h-8' />
                <span className='tracking-tight'>{t('Mijn rapportages')}</span>
                <span className='flex-grow-0 w-20 h-1 bg-black'></span>
              </h1>
            </div>

            <div className='px-4 mx-auto space-y-4 max-w-7xl sm:px-6 md:px-8'>
              <ReportOverviewCard maxShown={5} />
            </div>
            <div className='w-full border-t border-gray-300' />
          </>
        )}
        {hasUploadAccess(user) && uploadOverDue && (
          <div className='px-4 mx-auto space-y-4 max-w-7xl sm:px-6 md:px-8'>
            <Card>
              <div className='p-4 rounded-md bg-yellow-50'>
                <div className='flex'>
                  <div className='flex-shrink-0'>
                    <ExclamationIcon className='w-5 h-5 text-yellow-400' aria-hidden='true' />
                  </div>
                  <div className='ml-3'>
                    <h3 className='text-sm font-medium text-yellow-800'>{t('Belangrijk !')}</h3>
                    <div className='mt-2 text-sm text-yellow-700'>
                      <p>
                        {t(
                          'The Reporting Agency wacht op de aanlevering van uw data om rapportages te kunnen leveren.'
                        )}
                      </p>
                    </div>
                    <div className='mt-4'>
                      <div className='-mx-2 -my-1.5 flex'>
                        <Link
                          to='/app/data-delivery'
                          className='bg-yellow-50 px-2 py-1.5 rounded-md text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:ring-yellow-600'
                        >
                          {t('Nu data aanleveren')}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            <div className='w-full border-t border-gray-300' />
          </div>
        )}
        {/* <div className='px-4 mx-auto mt-12 space-y-4 max-w-7xl sm:px-6 md:px-8'>
          <h1 className='flex items-center justify-start mb-4 space-x-2 text-3xl font-bold text-gray-900 uppercase'>
            <NewspaperIcon className='w-8 h-8' />
            <span className='tracking-tight'>{t('Reporting agency nieuws')}</span>
            <span className='flex-grow-0 w-20 h-1 bg-black'></span>
          </h1>
          <Card>
            <BlogRoll />
          </Card>
        </div> */}
      </div>
    </div>
  );
};

import { XCircleIcon } from '@heroicons/react/outline';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

interface PanelProps {
  error?: SerializedError | FetchBaseQueryError | undefined;
}

export const PanelErrorFooter: React.FC<PanelProps> = ({ error }: PanelProps) =>
  error ? (
    <div className='p-4 rounded-md bg-red-50'>
      <div className='flex'>
        <div className='flex-shrink-0'>
          <XCircleIcon className='w-5 h-5 text-red-400' aria-hidden='true' />
        </div>
        <div className='ml-3'>
          <h3 className='text-sm font-medium text-red-800'>Er is iets fout gegaan:</h3>
          <div className='mt-2 text-sm text-red-700'>
            <ul className='pl-5 space-y-1 list-disc'>{JSON.stringify(error)}</ul>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );

/* eslint-disable react/jsx-key */
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Column } from 'react-table';
import { stringDateToLocaleString } from 'utils/dateUtils';

import { getUploadStatusTranslation } from '../../i18n/enumTranslations';
import { Table, TableCellChevronRight, TableCellActiveIcon, TableCellInactiveIcon } from '../generic/Table';
import { useGetUploadTasks } from '../services/api';
import { UploadTask } from '../services/apiGenerated';

export const UploadTasksTableSimple = () => {
  const { t } = useTranslation();

  const { data, error, isLoading } = useGetUploadTasks();

  const onRowClick = (row: UploadTask) => {
    navigate(`/management/uploadTasks/${row.id}`);
  };

  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/ban-types
  const columns: Column<UploadTask>[] = [
    {
      Header: t('Rapport').toString(),
      accessor: 'reportName',
    },
    {
      Header: t('Einddatum').toString(),
      accessor: (row) => {
        if (!row.dueDate) {
          return '';
        }
        return new Date(row.dueDate).toLocaleDateString('nl-nl');
      },
      sortType: (rowA, rowB) => {
        if (rowA.original.dueDate === undefined || rowA.original.dueDate === null) return 0;
        if (rowB.original.dueDate === undefined || rowB.original.dueDate === null) return 0;
        if (new Date(rowA.original.dueDate) > new Date(rowB.original.dueDate)) return 1;
        if (new Date(rowB.original.dueDate) > new Date(rowA.original.dueDate)) return -1;
        return 0;
      },
    },
    {
      Header: t('Status').toString(),
      accessor: (row) => getUploadStatusTranslation(row.status),
    },
    {
      Header: t('# Uploads').toString(),
      accessor: (row) => row?.uploads?.length ?? 0,
    },
    {
      Header: t('Uiterste datum').toString(),
      accessor: (row) => {
        if (!row.creationDate) {
          return '';
        }
        return stringDateToLocaleString(row.creationDate);
      },
      sortType: (rowA, rowB) => {
        if (rowA.original.dueDate === undefined || rowA.original.dueDate === null) return 0;
        if (rowB.original.dueDate === undefined || rowB.original.dueDate === null) return 0;
        if (new Date(rowA.original.dueDate) > new Date(rowB.original.dueDate)) return 1;
        if (new Date(rowB.original.dueDate) > new Date(rowA.original.dueDate)) return -1;
        return 0;
      },
    },
    {
      Header: t('Actief').toString(),
      accessor: (row) => (row.isActive ? TableCellActiveIcon : TableCellInactiveIcon),
    },
    {
      Header: ' ',
      accessor: () => TableCellChevronRight,
    },
  ];

  return <Table columns={columns} data={data ?? []} onRowClick={onRowClick} isLoading={isLoading} error={error} />;
};

import { AdminUserOverviewPage } from 'features/pages/management/AdminUserOverviewPage';
import { AdminUserPage } from 'features/pages/management/AdminUserPage';
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import RoleOnlyRoute from '../auth/RoleOnlyRoute';
import { MainLayout } from '../pages/layouts/MainLayout';
import { AdminDepartmentPage } from '../pages/management/AdminDepartmentPage';
import { AdminEmployeeOverviewPage } from '../pages/management/AdminEmployeeOverviewPage';
import { AdminEmployeesPage } from '../pages/management/AdminEmployeesPage';
import { AdminOrganizationPage } from '../pages/management/AdminOrganizationPage';
import { AdminOrganizationsOverviewPage } from '../pages/management/AdminOrganizationsOverviewPage';
import { AdminReportOverviewPage } from '../pages/management/AdminReportOverviewPage';
import { AdminReportPage } from '../pages/management/AdminReportPage';
import { AdminUploadTaskPage } from '../pages/management/AdminUploadTaskPage';
import { AdminUploadTasksOverviewPage } from '../pages/management/AdminUploadTasksOverviewPage';

const ManagementRoutes: React.FC = () => {
  return (
    <MainLayout>
      <Routes>
        <Route path=''>
          <Navigate to='/management/dashboard' />
        </Route>
        <RoleOnlyRoute path='organizations' roles={['admin']}>
          <AdminOrganizationsOverviewPage />
        </RoleOnlyRoute>
        <RoleOnlyRoute path='organizations/:id' roles={['admin']}>
          <AdminOrganizationPage />
        </RoleOnlyRoute>
        <RoleOnlyRoute path='reports' roles={['admin']}>
          <AdminReportOverviewPage />
        </RoleOnlyRoute>
        <RoleOnlyRoute path='reports/:id' roles={['admin']}>
          <AdminReportPage />
        </RoleOnlyRoute>
        <RoleOnlyRoute path='departments/:id' roles={['admin']}>
          <AdminDepartmentPage />
        </RoleOnlyRoute>
        <RoleOnlyRoute path='employees' roles={['admin']}>
          <AdminEmployeeOverviewPage />
        </RoleOnlyRoute>
        <RoleOnlyRoute path='employees/:id' roles={['admin']}>
          <AdminEmployeesPage />
        </RoleOnlyRoute>
        <RoleOnlyRoute path='adminusers' roles={['admin']}>
          <AdminUserOverviewPage />
        </RoleOnlyRoute>
        <RoleOnlyRoute path='adminusers/:id' roles={['admin']}>
          <AdminUserPage />
        </RoleOnlyRoute>
        <RoleOnlyRoute path='uploadtasks' roles={['admin']}>
          <AdminUploadTasksOverviewPage />
        </RoleOnlyRoute>
        <RoleOnlyRoute path='uploadtasks/:id' roles={['admin']}>
          <AdminUploadTaskPage />
        </RoleOnlyRoute>
      </Routes>
    </MainLayout>
  );
};

export default ManagementRoutes;

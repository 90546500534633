import { ArrowNarrowRightIcon, ArrowRightIcon } from '@heroicons/react/outline';
import { ContentState, convertFromRaw, EditorState } from 'draft-js';
import { RawDraftContentState } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { isJson } from 'utils/jsonHelper';

import { isNewReport, stringDateToLocaleString } from '../../utils/dateUtils';
import { Card } from '../generic/Card';
import { useGetReport } from '../services/api';

interface ReportOverviewProps {
  maxShown?: number;
}

const getEditorState = (description: string) => {
  if (!description) {
    return EditorState.createEmpty();
  }
  return !isJson(description)
    ? EditorState.createWithContent(ContentState.createFromText(description))
    : EditorState.createWithContent(convertFromRaw(JSON.parse(description) as RawDraftContentState));
};

const getEditorPlainText = (description: string) => {
  const editorState = getEditorState(description);
  if (!editorState) return '';
  return editorState.getCurrentContent().getPlainText('\u0001');
};
export const ReportOverviewCard = ({ maxShown }: ReportOverviewProps) => {
  const { t } = useTranslation();
  let opacity = 110;
  const { data: reports, isLoading } = useGetReport();
  const reportsToShow = maxShown === undefined ? reports : reports?.slice(0, maxShown);
  const list = reportsToShow?.slice().sort((a, b) => {
    if (!a.creationDate || !b.creationDate) return -1;
    return new Date(a.creationDate).getTime() < new Date(b.creationDate).getTime() ? 1 : -1;
  });
  return (
    <Card className='border-4 border-b-yellow-500'>
      <div className='flex flex-col'>
        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
            <div className='overflow-hidden border-gray-200 shadow border-b-transparant sm:rounded-lg'>
              {isLoading ? (
                <div style={{ fontSize: 25 }}>
                  <Skeleton count={5} />
                </div>
              ) : (
                <table className='min-w-full divide-y divide-gray-200'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th scope='col' className='relative px-6 py-3'>
                        <span className='sr-only'>Is nieuw</span>
                      </th>
                      <th
                        scope='col'
                        className='py-3 pl-2 pr-6 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                      >
                        {t('Naam')}
                      </th>
                      <th
                        scope='col'
                        className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                      >
                        {t('Omschrijving')}
                      </th>
                      <th
                        scope='col'
                        className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                      >
                        {t('Laatste wijziging')}
                      </th>
                      <th scope='col' className='relative px-6 py-3'>
                        <span className='sr-only'>Bekijk</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='bg-white divide-y divide-gray-200'>
                    {!list ||
                      (list.length === 0 && (
                        <tr>
                          <td colSpan={5} className='p-4 text-sm text-center'>
                            {t(
                              'Er zijn momenteel geen rapportages beschikbaar. U ontvangt een e-mail als er een nieuwe rapportage klaarstaat!'
                            )}
                          </td>
                        </tr>
                      ))}
                    {list?.map((report) => {
                      opacity = maxShown !== undefined ? opacity - 10 : opacity;

                      return (
                        <tr key={report.id} className={`opacity-${opacity} hover:opacity-100`}>
                          <td className='py-4 pl-2 pr-0 text-sm font-medium text-gray-900 whitespace-nowrap'>
                            {isNewReport(report.creationDate) && (
                              <span className='inline-flex items-center justify-center px-2 py-1 text-xs leading-none text-yellow-100 uppercase bg-yellow-600 rounded font-bolder'>
                                {t('Nieuw!')}
                              </span>
                            )}
                          </td>
                          <td className='py-4 pl-2 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap'>
                            {report.name}
                          </td>
                          <td className='max-w-lg px-6 py-4 text-sm text-gray-500 truncate whitespace-nowrap'>
                            {getEditorPlainText(report?.description ?? '')}
                          </td>
                          <td className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'>
                            {stringDateToLocaleString(report.creationDate)}
                          </td>
                          <td className='px-6 py-4 text-sm font-medium text-right whitespace-nowrap'>
                            <Link
                              to={`/app/reports/${report.id}`}
                              className='flex items-center justify-end text-yellow-600 hover:text-yellow-900'
                            >
                              <span>Bekijk rapportage</span>
                              <ArrowNarrowRightIcon className='w-5 h-5 ml-4' />
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                    {maxShown !== undefined && (
                      <tr>
                        <td></td>
                        <td colSpan={4} className='px-6 py-4 text-sm font-medium text-right whitespace-nowrap'>
                          <Link
                            to='/app/reports'
                            className='flex items-center justify-end text-yellow-700 hover:text-yellow-900'
                          >
                            <span>Bekijk alle rapportages</span>
                            <ArrowRightIcon className='w-5 h-5 ml-4' />
                          </Link>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

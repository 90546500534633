import { USER_FOUND } from 'redux-oidc';

/**
 * This middleware will check whether the USER_FOUND action has been dispatched,
 * and saves the access token in the payload of this action to localstorage. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AuthMiddleware = () => (next: any) => (action: any) => {
  if (action.type === USER_FOUND) {
    localStorage.setItem('access_token', action.payload.access_token);
    localStorage.setItem('redirect_uri', action.payload.state);
  }
  return next(action);
};

export default AuthMiddleware;

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import CallbackPage from '../features/auth/CallbackPage';
import LoginPage from '../features/auth/LoginPage';
import LogoutPage from '../features/auth/LogoutPage';
import PrivateRoute from '../features/auth/PrivateRoute';
import SilentRenewComponent from '../features/auth/SilentRenewComponent';
import { LoggedOutPage } from '../features/pages/LoggedOutPage';
// import LoginPage from '../features/pages/LoginPage';
import AppRoutes from '../features/routing/AppRoutes';
import ManagementRoutes from '../features/routing/ManagementRoutes';

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path=''
          element={
            <Navigate
              to={{
                pathname: `/app/`,
              }}
            />
          }
        />
        <Route path='login/:redirectTo' element={<LoginPage />} />
        <Route path='logout' element={<LogoutPage />} />
        <Route path='logged-off' element={<LoggedOutPage />} />
        <Route path='callback' element={<CallbackPage />} />
        <Route path='unauthorized' element={<p>Unauthorized access!</p>} />
        <PrivateRoute path='app/*' element={<AppRoutes />} />
        <PrivateRoute path='management/*' element={<ManagementRoutes />} />
        <Route path='silent_renew' element={<SilentRenewComponent />} />
        <Route path='*' element={<p>Errorpage - not found</p>} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

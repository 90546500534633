import { configureStore, SerializableStateInvariantMiddlewareOptions } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { backendApi } from '../features/services/api';
import { AuthMiddleware } from './../features/auth/authMiddleware';
import rootReducer from './rootReducer';

/** The following redux actions are throwing serialization errors,
 * because they store / pass objects that are not serializable.
 *
 * We're ignoring errors for these with opt-out so that our logs won't
 * be showing acceptable errors.accordion
 *
 * Note: These errors will only shown in a development environment
 */
const options: SerializableStateInvariantMiddlewareOptions = {
  ignoredActions: ['redux-oidc/USER_FOUND' /* Redux OIDC will pass an object with the user info */],
  ignoredPaths: ['auth.user'],
};

/** The redux store is configured with the combined reducers,
 * as well as the middleware we need to support.
 */
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: options }).concat(backendApi.middleware).concat(AuthMiddleware),
});

/**
 * Hot reload for the redux reducers. This will make live-reload work properly.
 */
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

setupListeners(store.dispatch);

export const getBase64 = (file: File, stripDataTag: boolean): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader?.result === null) {
        reject('File empty');
        return;
      }
      if (!stripDataTag) {
        resolve(reader.result.toString());
      }
      let encoded = reader.result.toString();
      if (stripDataTag === true) {
        encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
      }
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      resolve(encoded);
    };
    reader.onerror = (error) => reject(error);
  });
};

export const isNewReport = (date: string | undefined) => {
  if (!date) return false;
  const week = 7 * 24 * 1000 * 60 * 60;
  const provided = new Date(date);
  const weekAgo = new Date(Date.now() - week);

  return provided > weekAgo;
};

export const stringDateToLocaleString = (date: string | undefined | null) => {
  if (!date) {
    return '';
  }
  return new Date(date + 'Z').toLocaleString('nl-nl', { timeZone: 'Europe/Amsterdam' });
};

export const stringDateToLocaleDateString = (date: string | undefined | null) => {
  if (!date) {
    return '';
  }
  return new Date(date + 'Z').toLocaleDateString('nl-nl', { timeZone: 'Europe/Amsterdam' });
};

import { api as generatedApi } from './apiGenerated';

export const backendApi = generatedApi.enhanceEndpoints({
  addTagTypes: ['AdminEmployee', 'Organization', 'Department', 'Report', 'Employee', 'UploadTask', 'Document'],
  endpoints: {
    //#region Organizations
    getApiV1Organization: {
      providesTags: () => [{ type: 'Organization' }],
    },
    getApiV1OrganizationById: {
      providesTags: () => [{ type: 'Organization' }],
    },
    // alternate notation: callback that gets passed in `endpoint` - you can freely modify the object here
    postApiV1Organization: (endpoint) => {
      endpoint.invalidatesTags = ['Organization'];
    },
    putApiV1OrganizationById: (endpoint) => {
      endpoint.invalidatesTags = ['Organization'];
    },
    deleteApiV1OrganizationById: (endpoint) => {
      endpoint.invalidatesTags = ['Organization'];
    },
    //#endregion
    // #region Departments
    // Departments by organizationId
    getApiV1OrganizationByOrganizationIdDepartments: {
      providesTags: () => [{ type: 'Department' }],
    },
    // Departments by reportId
    getApiV1ReportByReportIdDepartments: {
      providesTags: () => [{ type: 'Department' }],
    },
    // Departments by employeeId
    getApiV1EmployeeByEmployeeIdDepartments: {
      providesTags: () => [{ type: 'Department' }],
    },
    getApiV1DepartmentById: {
      providesTags: () => [{ type: 'Department' }],
    },
    postApiV1Department: (endpoint) => {
      endpoint.invalidatesTags = ['Department', 'Organization'];
    },
    putApiV1DepartmentById: (endpoint) => {
      endpoint.invalidatesTags = ['Department', 'Organization'];
    },
    deleteApiV1DepartmentById: (endpoint) => {
      endpoint.invalidatesTags = ['Department', 'Organization'];
    },
    //#endregion
    //#region Reports

    // Reports by organizationId
    getApiV1OrganizationByOrganizationIdReports: {
      providesTags: () => [{ type: 'Report' }],
    },
    // Reports by departmentId
    getApiV1DepartmentByDepartmentIdReports: {
      providesTags: () => [{ type: 'Report' }],
    },
    // Reports by employeeId
    getApiV1EmployeeByEmployeeIdReports: {
      providesTags: () => [{ type: 'Report' }],
    },
    getApiV1Report: {
      providesTags: () => [{ type: 'Report' }],
    },
    getApiV1ReportById: {
      providesTags: () => [{ type: 'Report' }],
    },
    postApiV1Report: (endpoint) => {
      endpoint.invalidatesTags = ['Organization', 'Department', 'Employee', 'Report'];
    },
    postApiV1ReportByReportIdDistribute: (endpoint) => {
      endpoint.invalidatesTags = ['Report'];
    },
    putApiV1ReportById: (endpoint) => {
      endpoint.invalidatesTags = ['Organization', 'Department', 'Employee', 'Report'];
    },
    deleteApiV1ReportById: (endpoint) => {
      endpoint.invalidatesTags = ['Department', 'Organization', 'Report', 'Employee'];
    },
    //#endregion

    //#region Employees
    // Employees by organizationId
    getApiV1OrganizationByOrganizationIdEmployees: {
      providesTags: () => [{ type: 'Employee' }],
    },
    // Employees by departmentId
    getApiV1DepartmentByDepartmentIdEmployees: {
      providesTags: () => [{ type: 'Employee' }],
    },
    // Employees by reportId
    getApiV1ReportByReportIdEmployees: {
      providesTags: () => [{ type: 'Employee' }],
    },
    getApiV1EmployeeById: {
      providesTags: () => [{ type: 'Employee' }],
    },
    getApiV1Employee: {
      providesTags: () => [{ type: 'Employee' }],
    },
    putApiV1EmployeeById: (endpoint) => {
      endpoint.invalidatesTags = ['Organization', 'Department', 'Employee', 'Report'];
    },
    postApiV1Employee: (endpoint) => {
      endpoint.invalidatesTags = ['Organization', 'Department', 'Employee', 'Report'];
    },
    deleteApiV1EmployeeById: (endpoint) => {
      endpoint.invalidatesTags = ['Department', 'Organization', 'Report', 'UploadTask'];
    },
    //#endregion

    //#region AdminEmployeeInfoDto
    getApiAdminEmployee: {
      providesTags: () => [{ type: 'AdminEmployee' }],
    },
    getApiAdminEmployeeById: {
      providesTags: () => [{ type: 'AdminEmployee' }],
    },
    postApiAdminEmployee: (endpoint) => {
      endpoint.invalidatesTags = ['AdminEmployee'];
    },
    deleteApiAdminEmployeeById: (endpoint) => {
      endpoint.invalidatesTags = ['AdminEmployee'];
    },
    //#endregion

    //#region Upload Tasks
    // Get upload tasks by organization
    getApiV1OrganizationByOrganizationIdUploadTasks: {
      providesTags: () => [{ type: 'UploadTask' }],
    },
    getApiV1UploadTaskById: {
      providesTags: () => [{ type: 'UploadTask' }],
    },
    getApiV1UploadTaskByTaskIdFileAndFileId: {
      providesTags: () => [{ type: 'UploadTask' }],
    },
    postApiV1UploadTask: (endpoint) => {
      endpoint.invalidatesTags = ['UploadTask', 'Organization'];
    },
    postApiV1UploadTaskByTaskIdDistribute: (endpoint) => {
      endpoint.invalidatesTags = ['UploadTask'];
    },
    postApiV1UploadTaskByIdUploadFile: (endpoint) => {
      endpoint.invalidatesTags = ['UploadTask'];
    },
    putApiV1UploadTaskById: (endpoint) => {
      endpoint.invalidatesTags = ['UploadTask', 'Organization'];
    },
    putApiV1UploadTaskByIdStatus: (endpoint) => {
      endpoint.invalidatesTags = ['UploadTask'];
    },
    deleteApiV1UploadTaskById: (endpoint) => {
      endpoint.invalidatesTags = ['UploadTask', 'Organization'];
    },
    //#endregion

    //#region Document
    getApiV1Document: {
      providesTags: () => [{ type: 'Document' }],
    },
    // postApiV1Document: (endpoint) => {},
    // deleteApiV1DocumentById: (endpoint) => {
    //   endpoint.invalidatesTags = ['Document', 'Organization'];
    // },
    //#endregion
  },
});

//#region Organizations
export const useGetOrganizations = backendApi.useGetApiV1OrganizationQuery;
export const useGetMyOrganization = backendApi.useGetApiV1OrganizationMeQuery;
export const useGetOrganization = backendApi.useGetApiV1OrganizationByIdQuery;
export const useCreateOrganizations = backendApi.usePostApiV1OrganizationMutation;
export const useUpdateOrganizations = backendApi.usePutApiV1OrganizationByIdMutation;
export const useDeleteOrganizations = backendApi.useDeleteApiV1OrganizationByIdMutation;
//#endregion

// #region Department
export const useGetDepartmentByOrganization = backendApi.useGetApiV1OrganizationByOrganizationIdDepartmentsQuery;
export const useGetDepartmentByReport = backendApi.useGetApiV1ReportByReportIdDepartmentsQuery;
export const useGetDepartmentByEmployee = backendApi.useGetApiV1EmployeeByEmployeeIdDepartmentsQuery;
export const useGetDepartmentById = backendApi.useGetApiV1DepartmentByIdQuery;
export const useCreateDepartment = backendApi.usePostApiV1DepartmentMutation;
export const useUpdateDepartment = backendApi.usePutApiV1DepartmentByIdMutation;
export const useDeleteDepartment = backendApi.useDeleteApiV1DepartmentByIdMutation;
//#endregion

//#region Reports
export const useGetReportByOrganization = backendApi.useGetApiV1OrganizationByOrganizationIdReportsQuery;
export const useGetReportByDepartment = backendApi.useGetApiV1DepartmentByDepartmentIdReportsQuery;
export const useGetReportByEmployee = backendApi.useGetApiV1EmployeeByEmployeeIdReportsQuery;
export const useGetReportByReportId = backendApi.useGetApiV1ReportByIdQuery;
export const useGetReport = backendApi.useGetApiV1ReportQuery;
export const useCreateReport = backendApi.usePostApiV1ReportMutation;
export const useDistributeReport = backendApi.usePostApiV1ReportByReportIdDistributeMutation;
export const useUpdateReport = backendApi.usePutApiV1ReportByIdMutation;
export const useDeleteReport = backendApi.useDeleteApiV1ReportByIdMutation;
// Powerbi specific
export const useGetPowerBIReports = backendApi.useGetApiV1ReportPowerBiQuery;
export const useGetPowerBIEmbedParams = backendApi.useGetApiV1ReportByIdPowerBiEmbedQuery;
export const useGetFileEmbedParams = backendApi.useGetApiV1ReportByIdFileEmbedQuery;
//#endregion

//#region Employees
export const useGetEmployeeByOrganization = backendApi.useGetApiV1OrganizationByOrganizationIdEmployeesQuery;
export const useGetEmployeeByDepartment = backendApi.useGetApiV1DepartmentByDepartmentIdEmployeesQuery;
export const useGetEmployeeByReport = backendApi.useGetApiV1ReportByReportIdEmployeesQuery;
export const useGetEmployee = backendApi.useGetApiV1EmployeeQuery;
export const useGetEmployeeByEmployeeId = backendApi.useGetApiV1EmployeeByIdQuery;
export const useCreateEmployee = backendApi.usePostApiV1EmployeeMutation;
export const useUpdateEmployee = backendApi.usePutApiV1EmployeeByIdMutation;
export const useDeleteEmployee = backendApi.useDeleteApiV1EmployeeByIdMutation;
//#endregion

//#region Admin employee
export const useGetAdminUsers = backendApi.useGetApiAdminEmployeeQuery;
export const useGetAdminUserById = backendApi.useGetApiAdminEmployeeByIdQuery;
export const useCreateAdminUser = backendApi.usePostApiAdminEmployeeMutation;
export const useDeleteAdminUser = backendApi.useDeleteApiAdminEmployeeByIdMutation;
//#endregion

//#region Upload Tasks
export const useLazyGetUploadTasks = backendApi.useLazyGetApiV1UploadTaskQuery;
export const useGetUploadTasks = backendApi.useGetApiV1UploadTaskQuery;
export const useGetUploadTaskFile = backendApi.useLazyGetApiV1UploadTaskByTaskIdFileAndFileIdQuery;
export const useGetUploadTasksByOrgId = backendApi.useGetApiV1OrganizationByOrganizationIdUploadTasksQuery;
export const useLazyGetUploadTasksByOrgId = backendApi.useLazyGetApiV1OrganizationByOrganizationIdUploadTasksQuery;
export const useGetUploadTaskById = backendApi.useGetApiV1UploadTaskByIdQuery;
export const useCreateUploadTask = backendApi.usePostApiV1UploadTaskMutation;
export const useDistributeUploadTask = backendApi.usePostApiV1UploadTaskByTaskIdDistributeMutation;
export const useUpdateUploadTask = backendApi.usePutApiV1UploadTaskByIdMutation;
export const useUpdateUploadTaskStatus = backendApi.usePutApiV1UploadTaskByIdStatusMutation;
export const useDeleteUploadTask = backendApi.useDeleteApiV1UploadTaskByIdMutation;
export const useUploadDataForUploadTask = backendApi.usePostApiV1UploadTaskByIdUploadFileMutation;
//#endregion

// #region Documents
export const useGetDocuments = backendApi.useGetApiV1DocumentQuery;
export const useCreateDocument = backendApi.usePostApiV1DocumentMutation;
export const useDeleteDocument = backendApi.useDeleteApiV1DocumentByIdMutation;
export const useDownloadDocument = backendApi.useLazyGetDownloadByFileIdQuery;
// #endregion

//#region Other
export const useSendContactForm = backendApi.usePostApiV1ContactMutation;
//#endregion

/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RouteProps } from 'react-router';
import { Navigate, Route, useLocation } from 'react-router-dom';

import { RootState } from '../../app/rootReducer';
import { userInRole, UserRole } from './Roles';

/**
 * This function component renders a route only when the user is authorized and logged in.
 * If the user is not logged inl, the user is redirected to the login page.
 * @param props The route that should be rendered if the user is signed in.
 * @returns the route, or a log-in page.
 */

interface RoleRouteProps {
  roles: UserRole[];
}

const RoleOnlyRoute: React.FC<RouteProps & RoleRouteProps> = ({ roles, ...restProps }: RouteProps & RoleRouteProps) => {
  const location = useLocation();
  const { user } = useSelector((state: RootState) => state.auth);
  if (!roles) {
    return (
      <div>
        <h2>Error - RoleOnlyRoute used for path {location.pathname} but no role specified.</h2>
      </div>
    );
  }
  if (!user) {
    return (
      <Navigate
        to={{
          pathname: `/unauthorized`,
        }}
      />
    );
  }
  const roleMatch = roles.some((role) => userInRole(user, role));
  return roleMatch ? (
    <Route {...restProps} />
  ) : (
    <Navigate
      to={{
        pathname: `/unauthorized`,
      }}
    />
  );
};

export default RoleOnlyRoute;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import Config from '../../config';

export const apiBaseQuery = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: Config.apiUrl,
    prepareHeaders: (headers) => {
      const accessToken = localStorage.getItem('access_token');

      // If we have a token set in state, let's assume that we should be passing it.
      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`);
      }

      return headers;
    },
  }),

  endpoints: () => ({}),
});

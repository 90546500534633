/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from '@headlessui/react';
import { CheckCircleIcon, ExclamationIcon, InformationCircleIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { useEffect } from 'react';
import { Fragment, useRef, useState } from 'react';

interface ModalProps {
  isOpened: boolean;
  onClose: () => void;
  onAction?: () => void | Promise<unknown>;
  type: 'error' | 'warning' | 'info' | 'succes';
  title: string;
  description: string;
  actionButtonText: string;
  cancelButtonText?: string;
}
export const Modal = (props: ModalProps) => {
  const [isFinishing, setIsFinishing] = useState(false);
  const [isOpened, setIsOpened] = useState(props.isOpened);

  const cancelButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setIsOpened(props.isOpened);
  }, [props.isOpened]);
  const onModalActionButtonClick = () => {
    if (!props.onAction) {
      onModalClose();
      return;
    }
    const actionResult = props.onAction();
    if (actionResult instanceof Promise) {
      setIsFinishing(true);
      actionResult.then(function () {
        onModalClose();
      });
    } else {
      onModalClose();
    }
  };

  const onModalClose = () => {
    setTimeout(() => setIsFinishing(false), 500);
    props.onClose();
  };

  let actionIcon: JSX.Element;
  switch (props.type) {
    case 'error':
      actionIcon = <ExclamationIcon className='w-6 h-6 text-red-600' aria-hidden='true' />;
      break;
    case 'info':
      actionIcon = <InformationCircleIcon className='w-6 h-6 text-indigo-600' aria-hidden='true' />;
      break;
    case 'succes':
      actionIcon = <CheckCircleIcon className='w-6 h-6 text-green-600' aria-hidden='true' />;
      break;
    case 'warning':
      actionIcon = <ExclamationIcon className='w-6 h-6 text-yellow-600' aria-hidden='true' />;
      break;
  }

  const iconBgColor = classNames({
    'bg-red-100': props.type === 'error',
    'bg-indigo-100': props.type === 'info',
    'bg-green-100': props.type === 'succes',
    'bg-yellow-100': props.type === 'warning',
  });
  const buttonBgColor = classNames({
    'bg-red-600 hover:bg-red-700': props.type === 'error',
    'bg-indigo-600 hover:bg-indigo-700': props.type === 'info',
    'bg-green-600 hover:bg-green-700': props.type === 'succes',
    'bg-yellow-600 hover:bg-yellow-700': props.type === 'warning',
  });

  const focusRingColor = classNames({
    ' focus:ring-red-500': props.type === 'error',
    'focus:ring-indigo-500': props.type === 'info',
    'focus:ring-green-500': props.type === 'succes',
    'focus:ring-yellow-500': props.type === 'warning',
  });

  const disabledClick = classNames({
    'cursor-not-allowed': isFinishing,
  });
  return (
    <Transition.Root show={isOpened} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed inset-0 z-10 overflow-y-auto'
        initialFocus={cancelButtonRef}
        open={isOpened}
        onClose={() => onModalClose()}
      >
        <div className='flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75 -z-10' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='z-50 inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'>
              <div className='px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4'>
                <div className='sm:flex sm:items-start'>
                  <div
                    className={`flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto ${iconBgColor} rounded-full sm:mx-0 sm:h-10 sm:w-10`}
                  >
                    {actionIcon}
                  </div>
                  <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                    <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
                      {props.title}
                    </Dialog.Title>
                    <div className='mt-2'>
                      <p className='text-sm text-gray-500 break-words break-all'>{props.description}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse'>
                <button
                  disabled={isFinishing}
                  type='button'
                  className={`inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm ${buttonBgColor} ${focusRingColor} ${disabledClick}`}
                  onClick={() => onModalActionButtonClick()}
                >
                  {isFinishing && (
                    <svg
                      className='w-5 h-5 mr-3 -ml-1 text-white animate-spin'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                    >
                      <circle
                        className='opacity-25'
                        cx='12'
                        cy='12'
                        r='10'
                        stroke='currentColor'
                        strokeWidth='4'
                      ></circle>
                      <path
                        className='opacity-75'
                        fill='currentColor'
                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                      ></path>
                    </svg>
                  )}
                  {props.actionButtonText}
                </button>
                {props.cancelButtonText && (
                  <button
                    disabled={isFinishing}
                    type='button'
                    className={`inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 ${focusRingColor} sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm ${disabledClick}`}
                    onClick={() => onModalClose()}
                    ref={cancelButtonRef}
                  >
                    {props.cancelButtonText}
                  </button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

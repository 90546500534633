/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, useLocation } from 'react-router-dom';

import { RootState } from '../../app/rootReducer';
import { hasPortalAccess } from './Roles';

/**
 * This function component renders a route only when the user is authorized and logged in.
 * If the user is not logged inl, the user is redirected to the login page.
 * @param props The route that should be rendered if the user is signed in.
 * @returns the route, or a log-in page.
 */
const PrivateRoute = (props: any) => {
  const location = useLocation();
  const { user, isLoadingUser } = useSelector((state: RootState) => {
    return state.auth;
  });
  const isAuthorized = hasPortalAccess(user);
  if (isLoadingUser) {
    return <div>Loading...</div>;
  }

  return isAuthorized ? (
    <Route {...props} />
  ) : (
    <Navigate
      to={{
        pathname: `/login/${encodeURIComponent(location.pathname)}`,
      }}
    />
  );
};

export default PrivateRoute;

import classNames from 'classnames';

/* This example requires Tailwind CSS v2.0+ */

interface ButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
  type?: 'primary' | 'secondary' | 'white' | 'success' | 'error' | 'warning' | 'info';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  rounded?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  type = 'primary',
  size = 'md',
  rounded = 'true',
  className,
  children,
  ...restProps
}) => {
  const textColorClasses = classNames({
    'text-white': type !== 'white',
    'text-gray-700': type === 'white',
  });
  const backgroundColorClasses = classNames({
    'bg-white hover:bg-gray-50 border-gray-300 focus:ring-indigo-yellow': type === 'white',
    'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500': ['info', 'secondary'].includes(type),
    'bg-red-600 hover:bg-red-700 focus:ring-red-500': type === 'error',
    'bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500': ['warning', 'primary'].includes(type),
    'bg-green-600 hover:bg-green-700 focus:ring-green-500': type === 'success',
    'border-transparent': type !== 'white',
  });
  const sizeClasses = classNames({
    'text-xs': size === 'xs',
    'text-sm': ['sm', 'md'].includes(size),
    'text-base': ['lg', 'xl'].includes(size),
  });
  const xPaddingClasses = classNames({
    'px-3 py-1.5': size === 'xs',
    'px-3.5 py-2': size === 'sm',
    'px-4 py-2': size === 'md',
    'px-5 py-2': size === 'lg',
    'px-6 py-3': size === 'xl',
  });

  const roundedClasses = classNames({
    'rounded-md': rounded,
  });
  return (
    <button
      type='button'
      className={`inline-flex items-center ${xPaddingClasses} ${textColorClasses}  border ${sizeClasses} ${roundedClasses} shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${backgroundColorClasses} ${className}`}
      {...restProps}
    >
      {children}
    </button>
  );
};

export default Button;
